/*
 * @Author: Do not edit
 * @Date: 2021-07-21 15:08:08
 * @LastEditors: zhulin
 * @LastEditTime: 2021-09-23 10:09:53
 * @Description: Do not edit
 * @FilePath: \enfry-website\src\views\article\article.js
 */
const zhuanyeList=[
  {
    id: "zhuanye19",
    "src": require("@/assets/images/article/zhuanye11.jpg"),
    time:"2022-03-13",
    name: "数据驱动型的企业的7大特征",
    content:[
      "麦肯锡最近发布的研究报告，重新定义了数据驱动型企业的7大特征，作者进行了翻译，并进行了深度解读：",
      {
        "type":"img",
        "src":require("@/assets/images/article/zhuanye19.png")
      },
      "报告核心的7大特征英文原文：",
      {
        "type":"img",
        "src":require("@/assets/images/article/zhuanye19-1.png")
      },
      "数据驱动型企业具备的7大特征：",
      "1. 数据嵌入到每个决策场景，互动和流程，实现决策在线；",
      " 内涵：经验+数据的决策才是更好的决策。",
      
      "2. 数据是实时处理和共享的;",
      "内涵：数字化的核心本质之一是数据实时共享，而不是数据的事后分析，过 期的数据，没有太大的价值，就像大家都不吃过期食品的道理一样。",
      
      "3. 灵活的数据仓，能够做到随时随地的整合与可用；",
      " 内涵：需要做到技术普惠，需要赋能企业驾驭数据的能力。",

      "4. 数据运营模型要把数据当成产品；",
      "内涵：数据是核心生产要素，数据驱动能力是企业的核心竞争力；企业的核心竞争力在于算法，算力，交易和数据的四个飞轮要素的相互驱动算法和算力提高交易的效率，交易产生的数据又会不断的优化企业的算法和算力。",
      
      "5. 首席数据官（CDO）的角色是不断扩展数据价值；",
      "内涵：实时动态的数据才有价值，企业需要具备这种能力才能不断扩展数据价值。",

      "6. 数据生态的合作伙伴的会员体系将成为常态；",
      " 内涵：企业数据和合作伙伴内联外通，才能更好的形成生态共赢。",

      "7. 数据管理的首要和默认的三个前提：数据隐私，数据安全和数据弹性；",
      "内涵：一条数据多条结果需要针对不同的使用对象进行确权，授权，协同、和价值共享，才能长久。",

      "小结：" ,

      "en+数字化的对企业数字化转型设计思想和麦肯锡的观点是一致的，也在不断实践和运用这套理论和零代码的业财税一体化工具去帮助企业决策者实现决策在线，实现数据驱动，即从“人找数据”到“数据找人”。",
      
      "公司简介",
      
      "湖南英富莱网络技术有限公司始建于2016年，经过5年时间和近亿元的投入，精心打造出en+数字化赋能平台（简称en+数字化）。该平台是目前国内唯一的一家具备零代码搭建能力的业财税一体化的数字化赋能平台，也是国内领先的赋能企业规范上市的数字化服务商。en+数字化通过“1+N”的模式，即通过一个数字化底座实现N种数字化能力和方案，赋能企业落地经营管理的全场景数字化（包括人，财，业，物的多维数字化场景融合），并结合产业，数字化技术与金融的三大要素推动产业数字化转型。公司针对企业的不同发展阶段推出了三大产品线：BDA极数通（针对小微企业），BDA老板通（针对发展型企业），BDA上市通（针对拟上市企业）。",
      
      "en+数字化已经参与和实现了各类行业与产业数字化的建设，包括数字化物流，数字化医疗，数字化汽贸城，数字化工地，数字化园区，数字化工程管理，数字化律所，数字化农业，智能制造，数字化环保节能等行业或产业的硬软结合的数字解决方案。",    
      
      "en+数字化旗下产品：",

      {
        "type":"img",
        "src":require("@/assets/images/article/chanpin.jpg")
      }
    ]
  },
  {
    id: "zhuanye18",
    "src": require("@/assets/images/article/zhuanye18.jpg"),
    time:"2022-01-16",
    name: "订单全生命周期管理解决方案",
    content:[
      "背景",

      "中国经济进入结构化调整期已经成为共识，为了避免内卷， 小众化，定制化生产和服务逐步成为主流，越来越多的企业转型为以销定产的商业模式。订单型的企业非常渴望通过数字化转型提升人效和项目周转率，但是这些企业在数字化转型方面遇到了很多问题，非常需要可落地的解决方案和工具。",
      
      "一、痛点：",
      "1. 人效不够高：项目环节多，参与方多，协同困难重重；",
      
      "2. 数据不流动：老板看不到动态经营数据；",
      
      "3. 交期不可控：项目进度，成本费用和回款异常缺乏动态提醒；",

      "4. 考核不到位：缺乏过程管理和动态考核；",
      
      "二、本质：",
      
      "1. 订单型企业在数字化转型方面缺乏充分的顶层设计和规划；",
      
      "2. 没有考虑数据是随着订单的全生命周期横向流动的问题；",

      "3. 缺乏一个能够通过零代码实现业财融合的数字化赋能平台来满足企业不断变化的业务和财务的需求；",
      
      "三、方案：",
      
      "1. 要建立从线索到回款（L2C）的数据闭环管理，修建企业数据流动的高速公路；",
      
      "2. 要通过业财融合的数字化解决方案，解决同源数据产生不同维度的数据结果，实现数据从业务到财务的自动化联动，以及从财务到业务的可追溯，可验证；",
      
      "3. 要通过”数据到人”的数字化设计思想，做到让数据跑，人不跑；让数据自动的在正确的时间，按照正确的维度推送给正确的数据消费者；",

      "四、效果：",

      "以一个典型的律所行业举例，律所是典型的订单型企业，在上完数字化律所解决方案以后，大成律所昆明所的马主任有以下的评价：",

      "“事事记录，操作极简，数据互通。”",

      "“事务所全体人员将通过数字化管理完成业务拓展、案件管理、财务管理、事务管理，实现高效率的全流程工作记录及考核评价。”",

      "“用最少的工作量，得到最高的工作效率、最好的工作质量。”",

      {src:"——马巍，大成律所昆明事务所主任", text: "right", type: "txt"},

      "公司简介",
      
      "湖南英富莱网络技术有限公司始建于2016年，经过5年时间和近亿元的投入，精心打造出en+数字化赋能平台（简称en+数字化）。该平台是目前国内唯一的一家具备零代码搭建能力的业财税一体化的数字化赋能平台，也是国内领先的赋能企业规范上市的数字化服务商。en+数字化通过“1+N”的模式，即通过一个数字化底座实现N种数字化能力和方案，赋能企业落地经营管理的全场景数字化（包括人，财，业，物的多维数字化场景融合），并结合产业，数字化技术与金融的三大要素推动产业数字化转型。公司针对企业的不同发展阶段推出了三大产品线：BDA极数通（针对小微企业），BDA老板通（针对发展型企业），BDA上市通（针对拟上市企业）。",
      
      "en+数字化已经参与和实现了各类行业与产业数字化的建设，包括数字化物流，数字化医疗，数字化汽贸城，数字化工地，数字化园区，数字化工程管理，数字化律所，数字化农业，智能制造，数字化环保节能等行业或产业的硬软结合的数字解决方案。",    
      
      "en+数字化旗下产品：",

      {
        "type":"img",
        "src":require("@/assets/images/article/chanpin.jpg")
      }
    ]
  },
  {
    id: "zhuanye17",
    "src": require("@/assets/images/article/zhuanye1.jpg"),
    time:"2021-12-20",
    name: "数字化律所小场景（二）；效率神器：会议表决数字化",
    content:[
      "律所是一个典型的合伙型组织，所以合伙人，股东，董事会需要开会表决的事项会非常的多。随着律所规模的扩大，合伙人的增加，传统的靠人工来完成组织会，行使表决，统计与判定表决结果，会非常的耗时耗力。如何用数字化的方案来高效完成会议表决的闭环管理，请读者们认真观看视频和对比一下，过去的传统方式与数字化方案带来的差异。",

      "过去：",
      
      "1. 会议邀请：基本靠人单独通知，或者微信群通知；",
      
      "2. 参会确认：基本靠人反复确认是否能来参会；",
      
      "3. 参会确认：基本靠人手工统计请假人数与转让表决人数；",

      "4. 参会表决：基本靠人逐一统计表决事项；表决人只能逐一表决会议事项；",

      "5. 表决处理：基本靠人统计表决数据，靠人根据规则判定表决结果，容易错漏；",

      "6. 表决结果:   基本靠人处理会议表决结果告知书，逐一通知，或者微信群通知；",
      
      "现在：",
      
      "1. 会议邀请：系统可基于不同的固定场景会议的自动发起邀约参会人；",
      
      "2. 参会确认：系统可根据是否参会回执自动统计确认结果；",

      "3. 参会确认：系统自动统计请假与转让表决权的数据；",

      "4. 参会表决：系统可以实现多事项同时批量表决；",

      "5. 表决处理：系统可以根据预设规则自动判定表决结果，实时呈现，实现零等待；",

      "6. 表决结果: 系统可以自动生成会议表决结果告知书，自动推送通知或者自动邮件发送，也可以在线打印；",

      "总结：",
      
      "这个小场景把原来冗余的，耗时耗力的简单重复劳动，通过数据联动的方式，让原来靠人来确认，统计和判断的琐碎的低效率的会议表决工作，变成了的自动化，人机协同的高效率工作，解放了人工，提高的会议表决效率和会议组织者的协同效率。这个场景的解决方案把人，场景与数据之间的关系设计与表达的非常的清晰，即人在基于业务场景产生不同的数据的状态，口径，维度，角度，需要通过数据联动的方案设计把业务场景的每个细节的逻辑连接起来，让数据跑，人不跑。",

      "公司简介",
      
      "湖南英富莱网络技术有限公司始建于2016年，经过5年时间和近亿元的投入，精心打造出en+数字化赋能平台（简称en+数字化）。该平台是目前国内唯一的一家具备零代码搭建能力的业财税一体化的数字化赋能平台，也是国内领先的赋能企业规范上市的数字化服务商。en+数字化通过“1+N”的模式，即通过一个数字化底座实现N种数字化能力和方案，赋能企业落地经营管理的全场景数字化（包括人，财，业，物的多维数字化场景融合），并结合产业，数字化技术与金融的三大要素推动产业数字化转型。公司针对企业的不同发展阶段推出了三大产品线：BDA极数通（针对小微企业），BDA老板通（针对发展型企业），BDA上市通（针对拟上市企业）。",
      "en+数字化已经参与和实现了各类行业与产业数字化的建设，包括数字化物流，数字化医疗，数字化汽贸城，数字化工地，数字化园区，数字化工程管理，数字化律所，数字化农业，智能制造，数字化环保节能等行业或产业的硬软结合的数字解决方案。",    
      "en+数字化旗下产品：",
      {
        "type":"img",
        "src":require("@/assets/images/article/chanpin.jpg")
      }
    ]
  },
  {
    id: "zhuanye16",
    "src": require("@/assets/images/article/zhuanye16.jpg"),
    time:"2021-12-04",
    name: "数字化小场景：收款与认款效率的神器 - 数字化收银台",
    content:[
      {
        "type":"img",
        "src":require("@/assets/images/article/zhuanye16.jpg")
      },
      "最近，个人静态二维码收款方式受到约束，成为大家的关注的热点。企业如何利用二维码的快捷方式提高收款效率以及销售与财务的认款效率？",

      "一、 痛点：",
      "目前中小企业收款面临的主要问题，我们做了一下总结，有很多的隐性成本是中小企业主忽略了的：",
      
      "1. 资金成本：移动支付手续费费率高；",
      
      "2. 资金风险：销售人员用私人账号收款有资金占用和税务风险；",
      
      "3. 管理成本：销售人员与财务人员在回款认领方面耗时耗力；",

      "4. 绩效成本：销售人员绩效考核因为回款认领不及时影响士气；",
      
      "二、本质",
      
      "企业的合同编号，合同金额，销售人员，以及付款方的数据是割裂的，没有形成关联。所以需要人工去匹配和认领，以及在回款过程中出现的资金风险，导致了上述的痛点问题。",
      
      "三、方案：",
      
      "通过二维码的庞大的信息聚合能力，在客户付款的时候，扫描基于合同信 息  生成特定的二维码，就可以做到合同回款的精准匹配。原理是这个特定的二维码包含了企业的合同编号，合同金额，销售人员的信息，能够保证付款方与合同信息，合同金额精准匹配，解决了人工认款的重复劳动，提高回款的效率，安全性和及时性。注意，如果是大额的收款，可以通过合同对应生成付款链接的方式完成支付。",
      
      "看似简单的数据联动与匹配，会引出一个数字化方案设计的思考，即如何让合同标号，合同金额，销售人员信息与付款方信息关联？表面上看起来是二维码起到的作用，本质上是一个小的数据中台的解决方案，即需要把合同系统的数据，HR系统的数据和网银的数据做连接和实时的关联匹配的处理。en+数字化就起到了这个方案落地实现的关键的数据作用，即连接，处理和过程风险控制。",
      
      "四、效果：",

      "拿智联招聘的案例举例，智联招聘月平均回款数十万笔，3000多销售人员，因为大部分小微企业主在签约用的主体和付款的主体经常不一致，导致业务和财务对账耗时耗力。用了这个解决方案以后，原来应收部门21个人变为2个人，转岗19人转岗，每年至少节省200万元。",
      
      "公司简介",
      
      "湖南英富莱网络技术有限公司始建于2016年，经过5年时间和近亿元的投入，精心打造出en+数字化赋能平台（简称en+数字化）。该平台是目前国内唯一的一家具备零代码搭建能力的业财税一体化的数字化赋能平台，也是国内领先的赋能企业规范上市的数字化服务商。en+数字化通过“1+N”的模式，即通过一个数字化底座实现N种数字化能力和方案，赋能企业落地经营管理的全场景数字化（包括人，财，业，物的多维数字化场景融合），并结合产业，数字化技术与金融的三大要素推动产业数字化转型。公司针对企业的不同发展阶段推出了三大产品线：BDA极数通（针对小微企业），BDA老板通（针对发展型企业），BDA上市通（针对拟上市企业）。",
      "en+数字化已经参与和实现了各类行业与产业数字化的建设，包括数字化物流，数字化医疗，数字化汽贸城，数字化工地，数字化园区，数字化工程管理，数字化律所，数字化农业，智能制造，数字化环保节能等行业或产业的硬软结合的数字解决方案。",    
      "en+数字化旗下产品：",
      {
        "type":"img",
        "src":require("@/assets/images/article/chanpin.jpg")
      }
    ]
  },
  {
    id: "zhuanye15",
    "src": require("@/assets/images/article/zhuanye15.jpg"),
    time:"2021-11-20",
    name: "数字化律所小场景(一）效率神器：合同，邮件与案源跟进，数据联动",
    content:[
      {
        "type":"img",
        "src":require("@/assets/images/article/zhuanye15.jpg")
      },
      "律师如何快速起草合同，并且通过邮件将起草的合同快速发给客户，并且每一封邮件自动归集到客户跟进记录里面，后续能够快速关联查询？",

      "过去：",
      
      "1. 合同起草费时费力，并且原来的合同模板没有适应法律法规的变化，容易错漏和带来法律风险；",
      
      "2. 合同的在线编辑与邮件的发送割裂，需要在邮件中多做一步上次合同附件的操作；",
      
      "3. 邮件发送记录与客户的跟进记录割裂，要在邮箱和CRM两个系统去查找；",
      
      "现在：",
      
      "1. 直接下载法天使合同库的合同，完成在线编辑，合同条款实时更新当前法律法规变化，省时，省心，降低错漏的法律风险；",
      
      "2. 合同在线编辑与邮件一键发送一气呵成，提高律师工作效率；",
      
      "3. 邮件发送记录自动归集到客户的跟进记录里面，方便律师随时关联查询；",
      
      "总结：",
      
      "这个小场景把原来冗余的，耗时耗力的简单重复劳动，通过数据联动的方式，让原本割裂的合同起草，邮件管理以及客户跟进管理连接在一起，完成了一个律师对客户合同服务的一个小闭环，提高了律师的工作效率和协同效率。这个小场景把数字化优势淋漓尽致的展示出来，即通过人机协同的方式带来价值，通过连接的生态方式来做到优势结合，让专业的人做专业的事情。",
      
      "en+数字化简介：为企业提供数字化服务的咨询和工具落地的数字化服务平台。en+数字化是一个去代码化，基于数据全生命周期管理为核心的，可搭建的数字化赋能平台，赋能给企业，专家和合作伙伴实现1+N的共赢共建模式，即通过一个数字化赋能底座搭建N种数字化能力，实现企业的数据的内联外通，人机协同，为所有的参与者创造价值。",
      
      "作者简介：曾亮， en+数字化创始人兼CEO，1998年毕业于西南财经大学会计系，加拿大注册会计师（CGA），加拿大苏立克商学院（Schulich School of Businesss）MBA, 23年财务和企业管理经验，多家跨国公司以及互联网公司财务高管，数字化转型专家，业财融合专家，原百度移动游戏CFO。11年智能财务和企业数字化转型实战经验。 "    
    ]
  },
  {
    id: "zhuanye14",
    "src": require("@/assets/images/article/zhuanye14.jpg"),
    time:"2021-06-21",
    name: "数字化财务部发展趋势",
    content:[
      "在2021年十四五规划中，数字化中国建设是其中的重点规划，政府，企业和个人都不是在思考要不要做，而是在思考如何做，如何应对数字化转型问题。企业的财务部的组织架构以及财务人员都要面对的是核算财务无人化，业务财务数字化和战略财务共享化的这三大变化趋势，数字化财务的发展趋势也越来越明显。",

      "首先，财务人员的结构正在发生变化；",
      
      "1. 核算财务无人化",
      
      "在数字化时代，基于财务准则的凭证和报表的处理已经内置到业财融合的数字化平台中，由数字化平台根据业务场景自动生成核算凭证和财务报表以及纳税申报表，几乎不需要人为的干预，这部分简单重复劳动的核算财务被无人化（数字化员工或者机器人员工）替代；目前大型企业的核算财务在财务部的人员占比以倍数级的速度在下降。",
      
      "2. 业务财务数字化",
      
      "核算财务转型的方向是融入到业务中，成为财务BP，帮助业务降本增效，在过程中把业务风险，财务风险和税务风险降到企业可承受范围内，推动企业的良性发展。业务财务的核心关注点是如何设计数字化的业财融合方案，对业务实现动态支持，动态管理和动态分析，为决策者提供有效的资源优化配置的建议。所以，数字化的思维，数字化的平台将是业务财务不断提升自己和提升企业综合管理水平的利器。业务财务在财务部中的人员占比越来越大，并且能够支持越来越多的平台型企业，实现阿米巴化的管理。",
      
      "3. 战略财务共享化",
      
      "优秀的财务总监或者CFO已经到了战略财务的级别，能够帮助决策者提供战略级别的建议和思考是军师级别的人物，但战略财务是稀缺资源。在这个连接大于拥有的时代，战略财务要从一个成本中心变为利润中心，即成就自己也可以成就更多的企业。战略财务可以是从内部提拔和培养，可以是外部招聘，也可以是从第三方机构组成一个顾问化的团队为企业诊断和治疗，让企业进入更好的良性发展。",
      
      "其次，按企业不同的阶段，数字化财务部的组织结构的也会随之变化；",
      
      "1. 小微企业的数字化财务部将由数字化的代账公司托管完成；",
      
      "2. 中小企业的数字化财务部将由业务财务+无人化核算财务构成；",
      
      "3. 中大企业的数字化财务部将由财务总监+共享战略财务+业务财务+无人化核算财务构成；",
      
      "总之，在数字化时代下，简单重复劳动一定会被机器替代，财务人员从记录价值到创造价值和管理价值是一个必然的路径。数字化的财务部的结构也会随着财务人员结构的改变而改变，企业对财务人员的要求，评价和定位也会发生改变。财务人员每天和数据在一起，是最具备成为成为企业数字化专家和顾问的候选人，所以财务人员需要加倍努力，顺势而为，避免被淘汰。",
      
      "en+数字化简介：为企业提供数字化服务的咨询和工具落地的数字化服务平台。en+数字化是一个去代码化，基于数据全生命周期管理为核心的，可搭建的数字化赋能平台，赋能给企业，专家和合作伙伴实现1+N的共赢共建模式，即通过一个数字化赋能底座搭建N种数字化能力，实现企业的数据的内联外通，人机协同，为所有的参与者创造价值。",
      
      "作者简介：曾亮， en+数字化创始人兼CEO，1998年毕业于西南财经大学会计系，加拿大注册会计师（CGA），加拿大苏立克商学院（Schulich School of Businesss）MBA, 23年财务和企业管理经验，多家跨国公司以及互联网公司财务高管，数字化转型专家，业财融合专家，原百度移动游戏CFO。11年智能财务和企业数字化转型实战经验。 "    
    ]
  },
  {
    id: "zhuanye1",
    "src": require("@/assets/images/article/zhuanye6.jpg"),
    time:"2021-02-03",
    name: "en+数字化如何提升上市企业市值管理水平",
    content:[
      "市值管理是一个跨界的综合管理，即有意识和主动地运用多种科学、合规的方法和手段，以达到公司价值创造最大化、价值实现最大化和价值经营最优化的战略管理行为。随着科技进步和财务的转型，数字化已经逐渐在企业中开始落地，那么数字化是通过什么来推动企业市值管理的呢？即传统财务的转型如何做到从记录价值到创造价值的呢？"

      ,"根据多年的财务与企业管理经验，笔者认为站在管理会计的角度，企业的市值管理能力由两大主要因素决定：盈利能力和内控能力。三者之间的关系如下图："
      
      ,"en+数字化独家赞助《中国管理会计案例研讨会》"
      ,"企业的市值的管理最终反映为对企业信誉价值的管理。公式左侧里面任何一个因素的波动，都会对公式右侧的结果有倍增性的放大，即最终影响到企业的信誉价值。举个简单的例子，如果华为或者腾讯要融资，投资者是排队送钱，但是如果乐视要融资，估计现在很难有投资者响应。"
      
      ,"en+数字化以科技为手段，通过对企业盈利能力和内控能力的提升来达到提升企业市值管理的能力，主要体现在下面三个方面："
      
      ,"一、 en+数字化提升企业的盈利能力:"
      
      ,"“赚钱犹如针挑土，花钱犹如水推沙”，这句古话非常清晰的说明了节流的重要性。 en+通过科技手段来降本增 效，帮助企业做到轻资产，自运营，主要体现在以下几个方面："
      
      ,"省钱： 从企业的支出源头管控，团购化，去中间化，比价自动化和去报销化"
      
      ,"省人： 业财一体化，财务机器人，预算与标准控制实时化，薪酬自动化，内控自动化"
      
      ,"省时： 信息同步，流程再造，事件驱动，去重复操作"
      
      ,"如果按照股票的20倍市盈率来计算，如果企业节省60万元，意味着净利润增长60万元，那么市值增长是1200万 元。所以上市公司的市值提升，从省钱的角度更为容易和长效。"
      
      ,"二、en+数字化提升企业的内控能力"
      
      ,"COSO（美国反虚假财务报告委员会）在2017年9月6日公布新的内部控制框架指引，从原来的《内部控制整合 框架》（即战略执行层面的内部控制）转型为《企业风险管理框架》（ERM），这个转型是需要管理会计站在企业 的全 局视角驱动ERM的全方位落地。"
      
      ,"en+数字化站在管理会计的高度，通过建模的能力从多维度帮助企业从9大方面帮助企业更好的提升内控能力。"
      
      ,"1.投融资活动"
      
      ,"2.费用审批"
      
      ,"3.销售活动"
      
      ,"4.采购活动"
      
      ,"5.往来客户结算"
      
      ,"6.资金收入和支出"
      
      ,"7.成本核算"
      
      ,"8.风险控制"
      
      ,"9.利润分配"
      
      ,"很多上市企业未完全执行企业会计准则和制度要求进行内控制度的建立与完善工作，包括业务流、物流、 资金流、信息流之间的运行控制。由于没有这些基础，直接影响企业在尽职调查和问题整改、信息反馈中的效 率、质量和成本，形成上市合规的主要风险源头。"
      
      ,"如果没有有效的内控合规，投资者将失去对企业的投资信心，在多次被割韭菜以后，投资者日趋理性和成 熟，价值投资将逐渐成为主流。 内控合规做的好的企业，一定能获得市场的信任与青睐，而隐瞒和弄虚作假的企 业，内控合规不完善的企业，一定会被纳入投资者的黑名单。"
      
      ,"三、en+数字化提升企业数据金融化能力"
      
      ,"笔者在《数据金融化》的文章里面提到，现在Fintech（金融科技）的迅猛发展，企业传统的信贷模式也在被 颠覆。蚂蚁金服和芝麻信用在消费者的信贷市场，已经是利用消费者的数据通过智能分析来给消费者放贷。注意这 个前提是消费者的数据是真实有效和闭环的。而在企业市场，由于传统金融机构拿不到企业真实，有效规范的消费 数据，在信用评估体系方面手段保守和陈旧，导致企业市场借贷市场效率低下。随着金融科技公司不断的向企业市 场进军，传统的模式已经被打破，智能放贷的产品已经在各个中小银行试点，企业的融资和银行的放贷效率大幅提 升。"
      
      ,"en+数字化是讲财务科技与金融科技想结合，只要企业有真实，有效和规范的数据，企业就可以选择将数据金 融化，通过风险评估和风险画像与智能放贷的技术结合，就能够高效的融资，加快资金使用效率，扩大自身的产能和 提升盈利能力从而提升企业市值。"
      
      ,"最后，笔者有个大胆的设想，从长远来看，物联网与人工智能的发展，财务的发展也会从数字化走向智慧财务。 数字化是从解放传统生产力方面的做给机器做的赋能，而智慧财务会让机器更进一步的自动学习，纠错和思考，成为企业管理的智慧助手。如果智慧财务的到来，那么企业市值管理将进入一个更高效和便捷的时代，市值管理未来将是企业创意价值和信誉价值的综合管理，因为，企业的核心竞争力是在创意层面，而且生产，销售和企业管理将由人工智能和智慧财务来完成。"
      
      ,"en+数字化简介:"
      
      ,"-我们是国内第一家基于管理会计思维打造的数字化系统，不同于传统的核算会计系统，能够为企业提供多维度 和全局视角的数据分析的公司。"
      
      ,"-我们是国内唯一的一家以建模思维为核心打造的具备高可配置化的和提供专业的财务管理解决方案的Fintech(财 务科技)公司。"
      
      ,"-我们是一家以专业财务管理与互联网技术跨界融合的，通过SaaS（软件即服务）模式为企业提升盈利能力，内控 能力和融资能力的公司"
      
      ,"笔者简介："
      
      ,"曾亮，en+数字化 创始人兼CEO，加拿大注册会计师，MBA（Schulich School of Business）， 前百度移动游戏CFO，业务财务一体化专家，19年知名跨国公司和知名互联公司财务管理和企业管理经验。 锦狮投资市值管理专家委员会成员。"    ]
  },
  // {
  //   id: "zhuanye13",
  //   "src": require("@/assets/images/article/zhuanye13.jpg"),
  //   time:"2020-06-19",
  //   name: "小微企业数字化案例：家教门服务"
  // },
  {
    id: "zhuanye12",
    "src": require("@/assets/images/article/zhuanye12.jpg"),
    time:"2020-05-19",
    name: "CEO如何组建数字化转型团队",
    content:[
      {
        "type":"img",
        "src":require("@/assets/images/article-img/zhuanye12.jpg")
      },
      
      "CEO应该如何组建数字化转型团队？",

      "突如其来的疫情，让很多企业的CEO们都认知到企业数字化转型的必要性，即这是个生死题而不是选择题，所以很多CEO都不是在讨论该不该做的问题，而是已经凭着直觉就开始组建自己的数字化转型团队了。",

"CEO应该选择谁做为企业数字化转型的主导者，大部分凭直觉就是认为这个应该是CTO的事情。我们从下面几个层面分析一下，就知道这个问题在哪里了。",

"一、在人选层面：",

"在CXO里面谁最关注企业数据的全貌？ 答案是只有CEO和CFO，其他O都只是关注局部的数据全貌；并且对CFO的最基本要求是业财融合的推动者，即又懂业务 又精通财务，更够更好的把业务与财务翻译为管理层需要或者能看得懂的数据，即业务数据财务化，财务数据业务化，所以CFO是最适合做这个事情的人，而CTO在业务与财务方面有更大的鸿沟需要跨越。",

"二、在技术赋能层面：",

"en+数字化赋能平台的去代码化和赋能化的数字化技术，已经能够让不懂写代码的管理人员能够根据设计好的方案来搭建基于个性化的管理述求的解决方案，而不会受到技术开发瓶颈的制约。即定制化需求开发化完成与定制化需求配置化完成是有非常大的的区别。企业唯一不变的就是变化，灵活的配置适应企业的千变万化的需求，而传统的软件功能开发把业务逻辑与系统逻辑高耦合，导致后续企业业务需求发生变化的时候，企业会付出高昂的定制开发成本，这个是CEO从未预料到的。",

"三、在数字化转型的资源优化配置层面：",

"a. 专业的事情交给专业的团队来做，只有依赖技术是不能解决专业的问题，其实CTO压力也很大，能做的就是不断用新的技术来解决问题。而数字化转型的真正问题不是技术问题，而是认知问题和解决方案的问题。",

"b. 从费效比的层面，把CTO的团队用在最需要的业务端的技术研发层面，不要让浪费技术资源在内部的管理系统的优化和迭代上面，这个可以交给CFO与en+的专家团队一起来完成。",

"c. 如果非要让内部的技术团队开发内部的管理系统，那么业务的需求的排期优先级永远高于管理类的需求优先级，那么管理永远会拖业务的后腿，并且CEO的板子还打不下去，因为这个是没得选；",

"总结：",

"CEO在搭建数字化转型团队的时候，需要秉承一个双轮驱动的原则，即“方案是灵魂，工具是载体”。CFO是数字化转型方案的灵魂设计师，CTO要在技术层面很好的配合和帮助数字化转型方案的落地，en+的专家团队的作用是能够帮助CFO更好的设计数字转型的方案以及协同CTO及其对应的资源做好数字化转型方案的翻译与衔接，确保企业的数字化转型的道路上少走弯路。",

"如果CEO对您的CFO在数字化转型方案设计层面的能力还有一些不放心，您可以推荐CFO们来参加我们做的CFO数字化转型研习社，真心希望帮助到所有的想做好数字化转型的CEO和CFO们。"
    ]
  },
  {
    id: "zhuanye11",
    "src": require("@/assets/images/article/zhuanye11.jpg"),
    time:"2020-02-06",
    name: "CFO研讨会：制约财务人员转型的因素",
    content:[
      {
        "type":"img",
        "src":require("@/assets/images/article-img/zhuanye11/img1.png")
      },
      {
        "type":"txt",
        "src":"「中大型企业CFO转型与发展研讨会」在北京成功举行，特邀嘉宾与20+中大型企业CFO就财务人转型难、降本增效如何落地等议题展开探讨，从多维度、多层次进行碰撞与研讨。"
      },
      {
        "type":"txt",
        "src":"今天，为大家分享【制约财务人员转型的因素】课题的研讨内容。"
      },
      {
        "type":"txt",
        "src":"特邀嘉宾：北京舜天龙兴信息技术有限公司"
      },
      {
        "type":"txt",
        "src":"财务总监 赵鹏"
      },
      {
        "type":"txt",
        "src":"制约财务人员转型的因素基本假设，我们认为基本假设是财务人员需要转型，而且具备了转型需求，主要从三个方面来体现。"
      },
      {
        "type":"txt",
        "src":"第一是外部的需求，主要包括企业发展的区域、行业竞争压力。"
      },
      {
        "type":"txt",
        "src":"第二是财务人员自身也有转型的要求，包括对自身业务、水平或者其他外部的技术手段（比如财务机器人），对财务造成的相关焦虑感、压迫感。"
      },
      {
        "type":"txt",
        "src":"第三是现在技术手段已经达到了可以转型的条件，比如包括二维的信息和借贷双方将技术手段提升了一个屏障，能够反映出四维或者五维以及更多维整体。"
      },
      {
        "type":"txt",
        "src":"我们基于假设这个问题，做了一个界定，界定就是转型方向。"
      },
      {
        "type":"txt",
        "src":"针对界定，第一个问题是财务人的思维方式，思维方式与发展路径也有对应关系。"
      },
      {
        "type":"txt",
        "src":"第二个问题是各个企业财务、业务在整个的业务流程和所有事后数据没有反映出与之相对应的问题。"
      },
      {
        "type":"txt",
        "src":"第三个问题是公司对财务的定位不是特别一样。"
      },
      {
        "type":"txt",
        "src":"第四个问题是系统实现的方式。"
      },
      {
        "type":"txt",
        "src":"如果财务人员用一个单独核算系统，它没有与业务系统做进行关联，是单纯的信息孤岛，这个层面的问题也是转型的一个障碍。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/zhuanye11/img2.png")
      },
      {
        "type":"txt",
        "src":"针对这几个问题，我们提了一些相关的建议："
      },
      {
        "type":"txt",
        "src":"第一个是财务人员要转型，具备业务思维；"
      },
      {
        "type":"txt",
        "src":"第二个涉及到一个管理流程化的问题，优化包括一些管理权限或者是预算全面控制能力，考核评价等功能；"
      },
      {
        "type":"txt",
        "src":"第三个主要是业务流程再造；"
      },
      {
        "type":"txt",
        "src":"第四个是技术实践手段。"
      },
      {
        "type":"txt",
        "src":"以上，是我们组的主要研讨内容。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/zhuanye11/img3.png")
      },
      {
        "type":"txt",
        "src":"总结一下，非常好。"
      },
      {
        "type":"title",
        "src":"一、财务人员转型的终极目标"
      },
      {
        "type":"txt",
        "src":"我们都知道这个问题，即财务人员应该转型，但是财务人员转型的目标是什么？转型转什么？转型的重点是什么？记住，现在做的事情决定了未来。"
      },
      {
        "type":"txt",
        "src":"企业数据价值最大化管理是财务的终极目标，因为我们财务有个最大的金矿，一直没有变现。"
      },
      {
        "type":"txt",
        "src":"数据在我们身边，谁掌握数据谁就有未来。"
      },
      {
        "type":"txt",
        "src":"CFO们可以看到企业的数据全貌，知道风险点控制点问题在哪里，这个终极目标还可以再拆解。"
      },
      {
        "type":"txt",
        "src":"第一，我们转型的目标是提升自己的价值和地位。大家出来给老板打工挣钱实现自己的价值和存在感，这是必须的。"
      },
      {
        "type":"txt",
        "src":"第二，我们也有责任去提升财务的职业的地位，因为大多数企业对会计或者是财务的偏见是普遍性的。"
      },
      {
        "type":"txt",
        "src":"第三，财务怎么去提升企业的价值，才是真正能证明自己实力的，如果不能的话，前面说那两点都是说说而已。"
      },
      {
        "type":"title",
        "src":"二、财务人必须要跨界"
      },
      {
        "type":"txt",
        "src":"如何证明自己的价值？要主动帮助企业提升核心竞争力，让老板觉得你是有价值的人，你是不可或缺的人。"
      },
      {
        "type":"txt",
        "src":"我们要成为不可或缺的人，必须要做到什么？“跨界”。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/zhuanye11/img4.png")
      },
      {
        "type":"txt",
        "src":"人工智能它最大的问题在哪里？不要把它想的那么恐怖。到目前为止，它们只是解决单一领域的简单重复劳动的问题。"
      },
      {
        "type":"txt",
        "src":"AlphaGo战胜柯杰，它也是在围棋这个单一领域的算法，但它不代表情感，它没有其它的综合判断力。"
      },
      {
        "type":"txt",
        "src":"我们为什么要跨界，好处是什么？因为跨界增加了算法的复杂度，所以说它不能把我们替代。"
      },
      {
        "type":"txt",
        "src":"如果我们是做一个单一维度的算法，单一重复性劳动一定被替代，因为机器也可以做，而且会超越我们的物理极限。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/zhuanye11/img5.png")
      },
      {
        "type":"txt",
        "src":"这和刚才我们讲京东的案例是一样的，京东找500个人来盖章，就盖发票章每个人每分钟盖多少章？如果机器来批量盖的话或者用电子印章代替的话，它是能超越人的物理极限的。"
      },
      {
        "type":"txt",
        "src":"所以大家思考一下，就是为什么我们要去转型，是因为我们是有物理极限的问题。"
      },
      {
        "type":"txt",
        "src":"人会被人工智能替代的原因，就在于我们的物理极限不如它，它是机器，它可以不吃不喝，它只有电有网络就可以。"
      },
      {
        "type":"txt",
        "src":"现在我们面临最大的鸿沟就是懂专业不懂技术，懂技术不懂专业，这是天然的传统的分工造成的，也是我们的学习能力造成的。"
      },
      {
        "type":"txt",
        "src":"那么，怎么把鸿沟给跨越出来？就要有一种去代码化的工具，就像我们赋能给财务人员，用这种去代码工具去掌握数据的时候，财务就能掌握其未来的发展路径。"
      },
      {
        "type":"txt",
        "src":"去代码的好处是什么？把我们的专业能力翻译成可配置的规则和配置的算法，财务就很快能够把企业想要的数据想要的规则梳理清楚，企业的内控合规也做好了，预算管理过程管理也做好了，数据标签和分析也做好了。"
      },
      {
        "type":"txt",
        "src":"同时财务跟技术打交道没有太多，因为底层封装的就是一个数据的三大处理能力，就是采集处理和呈现。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/zhuanye11/img6.png")
      },
      {
        "type":"txt",
        "src":"对于老板来讲，以我的过往经验就是逐步影响。"
      },
      {
        "type":"txt",
        "src":"老板一点点会看到结果，会觉得财务能做这么多事情，都超出老板的想象和预期的时候，老板会对财务另眼相看。"
      },
      {
        "type":"txt",
        "src":"而这些预期就是财务掌握到数据能够做到业务部门分析不到的事情，因为业务最关注业绩和奖金。"
      },
      {
        "type":"txt",
        "src":"财务还要关注这以外的两件事情，就是成本和风险，还有整个业务的逻辑的合理性。"
      },
      {
        "type":"txt",
        "src":"财务天生在和数据打交道，所以财务转型的必由之路就是数字化转型，企业的转型必由之路也是数字化转型，这样企业才能够和竞争对手去抗衡。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/zhuanye11/img7.png")
      },
      {
        "type":"txt",
        "src":"大家觉得恐龙和一个兔子，谁的反应快？肯定是兔子，因为它敏捷，因为它本身神经的传导的路径就会短，而恐龙踩上一根针，估计两分钟才感到痛。"
      },
      {
        "type":"txt",
        "src":"所以企业怎么让自己更敏捷，而不是陷入这种传统的思维定式的那种回路，反应一定慢。"
      },
      {
        "type":"txt",
        "src":"数字化转型是财务转型唯一的机会。"
      },
      {
        "type":"txt",
        "src":"大家都在争夺数据，财务对数据的洞察，对数据的理解，对数据价值的挖掘，就代表财务的价值和在公司的影响力。"
      },
      {
        "type":"txt",
        "src":"如果财务把影响力做好的话，没人能挑战财务的地位，因为跨界，所以不可或缺。"
      },
      {
        "type":"txt",
        "src":"财务要想提升价值，必须跨界。"
      },
      {
        "type":"txt",
        "src":"以上，就是第四组嘉宾分享的内容和曾亮老师做出的点评。"
      }
    ]
  },
  {
    id: "zhuanye10",
    "src": require("@/assets/images/article/zhuanye10.jpg"),
    time:"2020-01-11",
    name: "CFO研讨会：预算编制与预算控制的难题",
    content:[
      {
        "type":"txt",
        "src":"财务部门不仅是“账房先生”，还将指导企业未来的发展，引领企业的数字化转型。"
      },
      {
        "type":"txt",
        "src":"前不久，我们举行了「中大型企业CFO转型与发展研讨会」，与20+中大型企业CFO针对资本市场遇冷，不同阶段债权、股权资源匮乏；行业集中、巨头垄断、红利消失、流量昂贵，发展机会和空间日益狭窄；各项成本增高；企业决策、管理、风险控制等日益复杂一系列问题进行分组讨论。"
      },
      {
        "type":"txt",
        "src":"今天，给大家分享第一组负责人对「预算编制与预算控制的难题」的思考与分享。"
      },
      {
        "type":"txt",
        "src":"特约嘉宾：51talk 财务总监 唐淳"
      },
      {
        "type":"txt",
        "src":"预算的核心目标是企业资源的优化配置，必须与企业的战略结合在一起。"
      },
      {
        "type":"txt",
        "src":"企业老板看重的是企业核心竞争力在哪里，以及要如何优化资源配置？"
      },
      {
        "type":"txt",
        "src":"老板非常重视预算的原因，在于财务能不能用好预算。"
      },
      {
        "type":"txt",
        "src":"预算能够做得更加准确，或者在过程中控制得更好，这是我们作为CFO要去考虑的问题。"
      },
      {
        "type":"txt",
        "src":"假设企业初创阶段业务先行，费用的控制是很难进行的"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/zhuanye10/img1.jpg")
      },
      {
        "type":"title",
        "src":"其中，难度主要表现在以下3个方面："
      },
      {
        "type":"title",
        "src":"1. 财务本身思维局限导致预算很难推行"
      },
      {
        "type":"txt",
        "src":"多数财务人员往往是从财务角度看待问题，思维的局限使得CFO很难与CEO站在同一高度去判断公司的有效商业价值。"
      },
      {
        "type":"txt",
        "src":"导致财务的很多意见常常会被认为是阻碍公司业务发展，最终不予采纳，很难让预算真正被公司重视。"
      },
      {
        "type":"title",
        "src":"2. 多数情况下财务自身还不具备融资的能力"
      },
      {
        "type":"txt",
        "src":"企业在初创阶段需要大量资金，融资往往是由企业CEO负责的，财务只起到辅助的角色。"
      },
      {
        "type":"txt",
        "src":"初创阶段公司在跑马圈地阶段的商业价值上必须投入大量资源，而财务并不能够帮助公司去解决最紧要的资金问题。"
      },
      {
        "type":"txt",
        "src":"所以也很难让公司感觉到财务做这些预算，能够让企业在预算管理、预算控制下产生太大价值。"
      },
      {
        "type":"title",
        "src":"3. 企业管理层本身缺乏财务意识"
      },
      {
        "type":"txt",
        "src":"75%以上的管理者认为在企业初创阶段就是跑马圈地，业务跑起来才是最重要的，不需要财务来拖累手脚，所以很难真正让业务重视预算。"
      },
      {
        "type":"txt",
        "src":"因此财务在做预算的时候就显得非常被动，就算预算能够做起来也是形同虚设。"
      },
      {
        "type":"txt",
        "src":"真正在执行的阶段完全不考虑这个问题，就会处于一种费用失控的状态。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/zhuanye10/img2.jpg")
      },
      {
        "type":"txt",
        "src":"这是我们看到的初创阶段会面临的问题的3个方面，针对这样的问题应该怎样解决？"
      },
      {
        "type":"txt",
        "src":"我认为也可以从3方面考虑："
      },
      {
        "type":"title",
        "src":"1. 用业财数据说话"
      },
      {
        "type":"txt",
        "src":"对于管理层缺乏财务意识这方面，我们要用自己的专业知识不断的说服企业管理者，充分认识到预算对于公司的重要性。"
      },
      {
        "type":"txt",
        "src":"通过业务与财务数据的呈现让企业管理者看明白，假如不做预算对公司未来会产生的具体影响。"
      },
      {
        "type":"title",
        "src":"2. 打破财务思维 跨业务沟通"
      },
      {
        "type":"txt",
        "src":"首先财务人员要对自己有很高的职业要求，通过不断的深入研究与学习，多做一些跨界尝试。"
      },
      {
        "type":"txt",
        "src":"能够主动参与到业务的前期讨论和活动当中去，财务要能够确保自己真正懂业务，可以跟业务平等对话，能够从财务的专业的角度提出对业务真正有帮助的建议，使业务能够看到财务的价值。"
      },
      {
        "type":"txt",
        "src":"管理层能够看到财务价值，这个时候财务才能真正帮助公司去推行预算管理。"
      },
      {
        "type":"title",
        "src":"3. 引进专业人士"
      },
      {
        "type":"txt",
        "src":"许多财务是不具备融资能力的，这也需要大家通过学习掌握融资知识与能力。"
      },
      {
        "type":"txt",
        "src":"比较有效的办法是引入专业人才，通过更专业的人才来帮助团队在公司初创阶段具备融资能力。"
      },
      {
        "type":"txt",
        "src":"特邀嘉宾：en+数字化CEO曾亮 点评"
      },
      {
        "type":"txt",
        "src":"“财务人员其实不是大家想象的那么内向，不都是一群账房先生，一天到晚就是算个帐。"
      },
      {
        "type":"txt",
        "src":"在做到管理层的时候，更重要是在沟通、规划、思考，帮助企业管理者出谋划策。"
      },
      {
        "type":"txt",
        "src":"① 预算的核心目标是企业资源的优化配置"
      },
      {
        "type":"txt",
        "src":"其实预算最核心目标就是企业资源的优化配置，与企业的战略结合在一起。"
      },
      {
        "type":"txt",
        "src":"出发点一定要跟企业管理者的战略协同，必须要了解风险取向、风险偏好、战略规划以及公司的实际情况。"
      },
      {
        "type":"txt",
        "src":"② 财务人的第一要务是理解业务"
      },
      {
        "type":"txt",
        "src":"业财融合才能做好预算，如果业财不融合去做预算，其实就是无源之水。"
      },
      {
        "type":"txt",
        "src":"真正要把预算做成贴合企业的业务，切合企业的实战，财务人员必须要懂业务，一定要让业务先行。"
      },
      {
        "type":"txt",
        "src":"只有真正理解业务才能把预算做得好，这也是我们财务人的第一要务。"
      },
      {
        "type":"txt",
        "src":"③ 企业做预算要具体问题具体分析"
      },
      {
        "type":"txt",
        "src":"企业内部做预算不是一刀切，需要分析自己企业的业务线成熟度和发展的领域以及老板对业财的看法。"
      },
      {
        "type":"txt",
        "src":"有时候老板的眼光和视角跟我们不一样的时候，我们要去理解他，但是也要提醒他，因为风险与决策是相关的。”"
      },
      {
        "type":"txt",
        "src":"以上是第一组嘉宾分享的内容和曾亮老师对此作出的点评内容。"
      }
  ]
  },
  {
    id: "zhuanye9",
    "src": require("@/assets/images/article/zhuanye9.jpg"),
    time:"2019-11-07",
    name: "日报-企业管理的时间黑洞",
    content:[
      {
        "type":"title",
        "src":"一个简单需求"
      },
      {
        "type":"txt",
        "src":"这个简单的需求是我们的一个教育集团客户原来使用企业微信做各分子公司的日报管理，但是HR部门每天在用1个员工统计47个分子公司的日报的上报情况和业绩情况，并且非常痛苦，问我们能不能自动统计每天的日报上报数量和分子公司的业绩情况，毫无疑问，每个分子公司的日报填报人亦有同样的感受。"
      },
      {
        "type":"txt",
        "src":"这个简单的需求，我们是这样优化的"
      },
      {
        "type":"txt",
        "src":"一、利用en+的定时任务触发的能力，从手动触发日报变为定时自动触发；对比了市面上主流的软件，其操作流程为：4步"
      },
      {
        "type":"txt",
        "src":"1） 点开“日报”；"
      },
      {
        "type":"txt",
        "src":"2） 点击“新增”；"
      },
      {
        "type":"txt",
        "src":"3） 填写“日报”；"
      },
      {
        "type":"txt",
        "src":"4） 点击“提交”；"
      },
      {
        "type":"txt",
        "src":"en+的日报自动触发能力，去掉了用户点击“新增”的这一步骤，节约了用户的宝贵时间。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/zhuanye9/img1.gif")
      },
      {
        "type":"txt",
        "src":"二、把日报拆分为数据类和非数据类，数据类日报不再填写，由系统自动统计上报，只保留非数据类日报填写，并统一了填写的格式。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/zhuanye9/img2.png")
      },
      {
        "type":"txt",
        "src":"三、利用en+的自动赋值能力，自动把明日计划反写到今日计划里面，方便用户和阅读者做对比。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/zhuanye9/img3.gif")
      },
      {
        "type":"txt",
        "src":"四、利用en+的任务驱动能力，上报状态由系统自动识别且实时可视。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/zhuanye9/img4.gif")
      },
      {
        "type":"txt",
        "src":"优化后的结果"
      },
      {
        "type":"txt",
        "src":"总部不再需要一个HR专门统计日报的数据和日报的状态，各个分子公司的对应的日报填写时间大幅度压缩，减少了至少70%的日报工作量。每年至少帮助公司节省25万元的人力成本；"
      },
      {
        "type":"txt",
        "src":"HR总监评价:"
      },
      {
        "type":"txt",
        "src":"“ 解决了我们日报工作中的大问题，也为员工减负，提高了工作效率，超出了我的预期！”"
      },
      {
        "type":"txt",
        "src":"一次深度思考"
      },
      {
        "type":"txt",
        "src":"企业的日报的目的和重点到底在哪里？为什么一个简单的日报功能，很多所谓的巨头的企业服务公司都没有做好？"
      },
      {
        "type":"txt",
        "src":"企业日报的重点就是对当日的经营行为的量化和非量化信息的一个呈现，方便管理层能够迅速定位异常，对异常情况做出反馈和后续决策。"
      },
      {
        "type":"txt",
        "src":"目前大部分软件厂商都是从实现功能层面来解决企业的问题，即以流程为核心的思维将线下物理世界的流程搬到线上虚拟世界上面，导致日报是碎片化的，形式化的，缺乏关联性的。日报的填写人，统计人，阅读人都消耗了大量的时间，时效性，准确性差，无形中增加了企业的管理的时间成本。在时间就是金钱的企业价值衡量体系中，这个简直就是企业的时间管理黑洞。"
      },
      {
        "type":"txt",
        "src":"如果换个角度，站在数字化思维的角度来看待企业日报的问题，那么日报的时间黑洞问题就可以迎刃而解。理由是，我们可以把日报的结构分为数据与非数据两个层面，先优化日报的数据层面的效率和成本问题，即“去人化”，然后再优化非数据层面的问题。"
      },
      {
        "type":"txt",
        "src":"怎么在数据层面“去人化”，我们就需要按照三个标准来设计日报的数字化转型方案："
      },
      {
        "type":"txt",
        "src":"1） 企业的数据采集要更便捷；"
      },
      {
        "type":"txt",
        "src":"2） 人对数据的干预要更少；"
      },
      {
        "type":"txt",
        "src":"3） 数据流动更快；"
      },
      {
        "type":"txt",
        "src":"一套解决方案"
      },
      {
        "type":"txt",
        "src":"首先企业的日报体系，不是只用一个简单的工具作为信息呈现的载体，而是需要整体的解决方案来帮助企业实现对日报的管理目标。"
      },
      {
        "type":"txt",
        "src":"日报作为企业日常经营的信息的综合体，需要更加高效的为管理者的决策服务。那么在设计企业日报管理解决方案的时候，需要站在日报数据的全生命周期来管理，即需要思考企业的业务数据采集，处理和呈现的三个不同的阶段来设计企业的日报体系。每个企业的业务场景都包含了数据的三个生命阶段，一个数据的终点又是下一个场景数据的起点。数据间的连接，关联和翻译，如何形成数据闭环都是日报方案设计的核心。"
      },
      {
        "type":"txt",
        "src":"我们提供的站在数据全生命周期角度设计的日报解决方案包含的核心能力如下："
      },
      {
        "type":"txt",
        "src":"1） 日报自动赋值：自动实现日计划和当日完成进度的对比；"
      },
      {
        "type":"txt",
        "src":"2） 日报自动推送：系统自动向填报人推送标准格式的日报，自动统计日报填写状态；"
      },
      {
        "type":"txt",
        "src":"3） 看板实时可视：业务数据录入即转为可视化看板；"
      },
      {
        "type":"txt",
        "src":"4） 日报异常预警：业务数据异常，实时通知提醒；"
      },
      {
        "type":"txt",
        "src":"5） 数据的翻译和流转：一条业务数据，按处理规则生成多条数据结果并被下一个环节引用或者关联；"
      },
      {
        "type":"txt",
        "src":"日报体系方案建立起来以后，可以达到下面的效果："
      },
      {
        "type":"txt",
        "src":"1） 填报和统计人员无需再统计业务数据，无需重复劳动和沟通；"
      },
      {
        "type":"txt",
        "src":"2） 管理者可以实时高效地对企业的情况和异常有精准的把握，能够运筹帷幄，明察秋毫；"
      },
      {
        "type":"txt",
        "src":"我们保守测算了一下，即把节约的人力的时间换算成人力成本，效果相当惊人。一个1000人左右的企业，我们的方案能够为企业每年节约近130万的成本。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/zhuanye9/img5.png")
      },
      {
        "type":"title",
        "src":"en+数字化赋能平台简介"
      },
      {
        "type":"txt",
        "src":"en+数字化赋能平台是通过去代码化的赋能给企业管理者，专家，客户和生态来打造企业生态数据流动的高速公路，建立起生态间数据的互联互通，通过数据驱动生态共赢和成长。en+数字化赋能平台能够实现企业的5大在线能力，即员工在线，客户在线，业务在线，数据在线和最终的决策在线。"
      }
    ]
  },
  {
    id: "zhuanye2",
    "src": require("@/assets/images/article/zhuanye1.jpg"),
    time:"2019-07-31",
    name: "企业数字化转型案例：智联招聘收入中台案例",
    content:[
      {
        "type":"txt",
        "src":'智联招聘（Zhaopin）创建于1994年，目前拥有1.4亿职场人用户，累计合作企业数达400万家。智联招聘满足日常白领招聘需求，依托智联招聘的白领人群资源，以及智联大数据平台，为企业解决招聘问题 。旗下智联校园是面向大学生群体的业务板块，通过整合高校资源，为企业提供以雇主品牌咨询与建设、定制化校招（应届生/实习生）、全球化校招等系列服务 。智联卓聘以智联招聘为平台依托，专注于中高端人才招聘。整合全国猎头资源，向企业提供从"起点到终点"的一站式人才招聘服务。'
      },
      {
        "type":"title",
        "src":"快速发展中面临挑战"
      },
      {
        "type":"txt",
        "src":"随着智联招聘业务量的快速增长，同时因CMP系统（类CRM系统）对合同、发票及回款管理自动化功能的缺失。销售、出纳及会计投入大量人力成本及时间成本、手工线下处理回款款项匹配，这种方式已经严重制约了销售收款管理的质量与效率。"
      },
      {
        "type":"txt",
        "src":"如何实现回款信息、合同信息的自动匹配？"
      },
      {
        "type":"txt",
        "src":"如何彻底改变务人员手工重复对账工作的现状，提升销售与收款管理的质量与效率？"
      },
      {
        "type":"txt",
        "src":"管理者如何实时掌握销售与收款管理环节的数据全貌？"
      },
      {
        "type":"title",
        "src":"痛点总结分析"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/zhuanye1/img1.png")
      },
      {
        "type":"txt",
        "src":"智联招聘与en+数字化的携手是大势所趋，en+数字化致力于在信息化走向数字化的大势中，帮助智联招聘解决数据处理的瓶颈。"
      },
      {
        "type":"title",
        "src":"en+数字化是什么？"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/zhuanye1/img2.png")
      },
      {
        "type":"txt",
        "src":"是一个通过为企业修建数据流动的高速公路，提升企业的数据流动能力，从而实现企业的数据驱动运营和数据价值最大化的解决方案和落地工具的综合体。"
      },
      {
        "type":"txt",
        "src":"是一个以建模思维为核心打造的具备高可配置化的赋能化的产品。"
      },
      {
        "type":"txt",
        "src":"是一个专注解决企业数字化转型的三大难点问题（数据的连接，处理和翻译）的产品。"
      },
      {
        "type":"title",
        "src":"en+能帮助智联招聘什么？"
      },
      {
        "type":"txt",
        "src":"通过en+数字化平台强大、灵活的数据处理能力，帮助智联招聘打造智能化销售收款管理系统（Cash Return Automation以下简称CRA系统）"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/zhuanye1/img3.png")
      },
      {
        "type":"title",
        "src":"项目目标"
      },
      {
        "type":"txt",
        "src":"CRA系统与智联招聘在用CMP系统（销售管理系统）无缝集成，实现单点登录；"
      },
      {
        "type":"txt",
        "src":"CRA系统打通与银行接口，实现银企互联；实现回款信息的同步和资金余额的实时同步；自动获取银行收款记录，生成系统内收款数据；"
      },
      {
        "type":"txt",
        "src":"CRA系统从CMP系统获取合同信息、发票信息、历史收款信息，为应收款项的匹配提供依据；"
      },
      {
        "type":"txt",
        "src":"CRA系统具备自动认领回款的功能，将回款信息和合同信息按照设定的规则进行自动匹配，对未匹配通过的结果提供手动认领功能；"
      },
      {
        "type":"txt",
        "src":"CRA系统提供实时的应收、回款等报表，为管理层提供实时数据支撑；"
      },
      {
        "type":"txt",
        "src":"CRA系统与智联招聘在用财务系统（Oracle）实时交互对接，实现回款、回款冲销、业绩划转自动记账。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/zhuanye1/img4.png")
      },
      {
        "type":"title",
        "src":"/// 痛点1 ///"
      },
      {
        "type":"title",
        "src":"手动回款确认工作量大"
      },
      {
        "type":"txt",
        "src":"智联招聘的收款方式多样化，涵盖微信、支付宝、银联支付和银行转账，各种流水难以高质高效与合同、订单匹配，统计难度大；"
      },
      {
        "type":"txt",
        "src":"财务人员需要每天手工将银行导出的收款明细和CMP中的合同信息、发票信息进行多次人工匹配和校对；"
      },
      {
        "type":"txt",
        "src":"对于无法匹配成功的数据还要通过邮件形式发送给销售，由销售通过邮件认领，匹配通过的结果还需要整理后导入核算系统；"
      },
      {
        "type":"txt",
        "src":"每日销售、出纳及应收会计投入大量人力及时间来处理回款认领，整体效率不高，质量难以保障。"
      },
      {
        "type":"title",
        "src":"/// 优化1 ///"
      },
      {
        "type":"title",
        "src":"高质高效的回款自动化确认"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/zhuanye1/img5.png")
      },
      {
        "type":"txt",
        "src":"实时高效自动同步支付流水（银行转账、支付宝、微信支付）、CMP系统合同及订单数据；"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/zhuanye1/img6.jpg")
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/zhuanye1/img7.jpg")
      },
      {
        "type":"txt",
        "src":"系统预置多种自动回款匹配规则，同时设置规则优先级，实现实时自动回款匹配；"
      },
      {
        "type":"txt",
        "src":"自动化匹配回款后，系统自动生成待认领回款池，供销售人员手动线上认领回款。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/zhuanye1/img8.jpg")
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/zhuanye1/img9.jpg")
      },
      {
        "type":"txt",
        "src":"销售人员手动认领支持提供辅助证明资料，便于财务人员审核认领申请，并永久留痕；着实做到效率与风险控制并存；"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/zhuanye1/img10.jpg")
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/zhuanye1/img11.jpg")
      },
      {
        "type":"txt",
        "src":"回款冲销支持回款认领错误修正机制。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/zhuanye1/img12.jpg")
      },
      {
        "type":"title",
        "src":"/// 痛点2 ///"
      },
      {
        "type":"title",
        "src":"精准核算业绩带来挑战"
      },
      {
        "type":"txt",
        "src":"规模扩大的同时，分公司越来越多，部分非独立法人实体无法独立收款，总分公司和分公司之间存在代签合同、客户冲突、打错款等情况，造成了款项与业绩单位不一致的情况，如何准确核算各个分子公司及各个团队的销售业绩将迎来巨大挑战。"
      },
      {
        "type":"title",
        "src":"高质高效的回款自动化确认"
      },
      {
        "type":"title",
        "src":"/// 优化2 ///"
      },
      {
        "type":"title",
        "src":"业绩自动划转，精确核算分公司业绩"
      },
      {
        "type":"txt",
        "src":"CRA给智联赋能了业绩划转的能力，将业绩精准划转到业绩归属主体，通过跨层业绩划转，保证了业绩计算的准确性，实现了分公司独立业绩核算；"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/zhuanye1/img13.jpg")
      },
      {
        "type":"txt",
        "src":"系统预设业绩划转规则，回款认领后自动划转销售人员对应的销售业绩；"
      },
      {
        "type":"txt",
        "src":"支持手动对待划转金额大于0的本公司回款数据进行单条或批量划转；"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/zhuanye1/img14.jpg")
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/zhuanye1/img15.jpg")
      },
      {
        "type":"txt",
        "src":"支持对划转数据（包括已记账及未记账）进行冲销。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/zhuanye1/img16.jpg")
      },
      {
        "type":"title",
        "src":"/// 痛点3 ///"
      },
      {
        "type":"title",
        "src":"核算多人分摊业绩较复杂"
      },
      {
        "type":"txt",
        "src":"过去智联的销售人员无统一的系统来查看自己的销售业绩，多人签订的销售订单，在进行业绩分配时也极易产生分歧，销售人员对于业绩明细情况的查询需要经过财务手工制定报表才能得到结果，财务工作量大且效率相对较低。"
      },
      {
        "type":"title",
        "src":"/// 优化3 ///"
      },
      {
        "type":"title",
        "src":"销售分单自动业绩划转"
      },
      {
        "type":"txt",
        "src":"CRA系统为给智联赋能了销售分单的能力，运营人员在分单设定中可以对合同进行分单规则设定，设定规则后系统根据规则自动进行业绩划转，分单规则支持设置多条，可同时对多个对象进行业绩划转，减少了员工之间因为业绩划转不明确、不及时导致的争议。"
      },
      {
        "type":"txt",
        "src":"举例：三个销售人员共同拿下了一个合同共100万元，A分30%，B分20%，C分50%，先由总公司代收，系统会先把该笔收款计入总公司，再自动划到分公司（分公司分三笔划入，分别是A30万、B20万和C50万）        "
      },
      {
        "type":"txt",
        "src":"销售人员可以自助查询款项到账情况、自助查询合同的回款情况和应收情况、自助认领回款，无需再请财务人员协助查询相关信息，自身工作效率大大提升。"
      },
      {
        "type":"txt",
        "src":""
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/zhuanye1/img17.jpg")
      },
      {
        "type":"title",
        "src":"/// 痛点4 ///"
      },
      {
        "type":"title",
        "src":"销售人员交接后，业绩连续跟踪困难"
      },
      {
        "type":"txt",
        "src":"销售人员离职后，负责的合同需要转交给交接人，转交前后的回款要分别归属到离职人员和交接人，操作繁琐，给财务带来的压力大；"
      },
      {
        "type":"txt",
        "src":"销售团队重组后，实时响应销售回款业绩归属适配，存在极大压力。"
      },
      {
        "type":"title",
        "src":"/// 优化4 ///"
      },
      {
        "type":"title",
        "src":"批量销售及团队变更，实现业绩自动跟踪归属"
      },
      {
        "type":"txt",
        "src":"en+提供了销售变更的能力，员工离职后，系统自动将合同负责的销售人员自动变成交接人，后续回款自动归入交接人的业绩；"
      },
      {
        "type":"txt",
        "src":"销售团队整体进行拆分合并时，自动实现对相关合同销售团队的批量更新，高质高效的解决业绩归属团队的困扰。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/zhuanye1/img18.jpg")
      },
      {
        "type":"title",
        "src":"/// 痛点5 ///"
      },
      {
        "type":"title",
        "src":"未匹配回款手动汇总导入Oracle"
      },
      {
        "type":"txt",
        "src":"月末待认领池存在客户回款没有认领完，这种情况归属于客户的预收，只能由财务人员月末在Oracle系统中手动确认一笔预收凭证；"
      },
      {
        "type":"txt",
        "src":"次月再次手动冲销上月确认的预收数据；"
      },
      {
        "type":"title",
        "src":"/// 优化5 ///"
      },
      {
        "type":"title",
        "src":"未匹配回款自动闭环处理"
      },
      {
        "type":"txt",
        "src":"CRA系统支持月底自动识别待认领池流水数据，自动进行生成预收数据传递至Oracle系统；"
      },
      {
        "type":"txt",
        "src":"CRA系统自动识别已确认预收的流水认领后，自动生成预收冲销单据，自动实现预收转应收的凭证处理；"
      },
      {
        "type":"txt",
        "src":"CRA系统自动识别已确认预收的回款单做退款或冲销时，自动恢复预收冲销单据（确认预收）；"
      },
      {
        "type":"txt",
        "src":"CRA系统自动识别AP系统发起退款申请时，自动生成预收冲销单据；"
      },
      {
        "type":"txt",
        "src":"CRA确认的预收单据及预收冲销单据自动导入Oracle系统，生成预收账款相关凭证。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/zhuanye1/img19.jpg")
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/zhuanye1/img20.jpg")
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/zhuanye1/img21.jpg")
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/zhuanye1/img22.jpg")
      },

      {
        "type":"img",
        "src":require("@/assets/images/article-img/zhuanye1/img21.jpg")
      },
      {
        "type":"title",
        "src":"/// 痛点6 ///"
      },
      {
        "type":"title",
        "src":"人工处理客户退款，工作繁琐"
      },
      {
        "type":"title",
        "src":"/// 优化6 ///"
      },
      {
        "type":"title",
        "src":"系统自动化处理客户退款"
      },
      {
        "type":"txt",
        "src":"CRA实时与AP系统进行交互对接，AP系统实时获取CRA回款相关数据，发起退款流程审批；"
      },
      {
        "type":"txt",
        "src":"CRA系统提供标准API接口，接收AP系统退款申请数据，实时交互数据；"
      },
      {
        "type":"txt",
        "src":"CRA系统接收退款申请后，自动触发回款冲销、业绩划转冲销、预收冲销、预收确认、流水更新等动作；"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/zhuanye1/img22.jpg")
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/zhuanye1/img23.png")
      },
      {
        "type":"txt",
        "src":"CRA系统依据退款数据，自动更新待认领额度、回款认领、业绩划转等数据。"
      },
      {
        "type":"title",
        "src":"/// 痛点7 ///"
      },
      {
        "type":"title",
        "src":"看不到数据全貌"
      },
      {
        "type":"txt",
        "src":"过去智联由财务手工处理大量数据，底层数据无法全面且有效的采集，缺少统一归集途径，数据之间无法相互利用，易形成“数据孤岛”，数据及信息不及时，导致各层级管理层数据化决策成本奇高。"
      },
      {
        "type":"title",
        "src":"/// 优化7 ///"
      },
      {
        "type":"title",
        "src":"实时自助查询各种维度口径数据"
      },
      {
        "type":"txt",
        "src":"CRA系统通过连通数据孤岛，形成统一归集，数据动态实时更新；"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/zhuanye1/img24.jpg")
      },
      {
        "type":"txt",
        "src":"城市流水汇总表、城市回款汇总表（层层穿透，总公司→区域（华南/华北）→城市→部门）、部门回款汇总表、纵向流水表（银行账户、支付宝、微信流水明细）、业绩划转表（各个公司业绩的划入划出）、业绩查询表等；"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/zhuanye1/img25.png")
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/zhuanye1/img26.png")
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/zhuanye1/img27.jpg")
      },
      {
        "type":"txt",
        "src":"多层次多角度的报表供企业集团财务，运营、销售人员及各层级管理者随时随地查阅，为管理层的决策提供了强有力的依据；"
      },
      {
        "type":"txt",
        "src":"销售人员可以自助查询款项到账情况、自助查询合同的回款情况和应收情况、自助认领回款，无需再请财务人员协助查询相关信息，自身工作效率大大提升；销售人员积极性及满意度得到有效提升；"
      },
      {
        "type":"txt",
        "src":"各级财务、运营随时查阅各分公司、各销售团队、各销售人员、各客户合同签约情况、到款情况、欠款情况及发票情况，有效还原各个业务环节数据全貌"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/zhuanye1/img28.jpg")
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/zhuanye1/img29.jpg")
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/zhuanye1/img30.jpg")
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/zhuanye1/img32.jpg")
      },
      {
        "type":"title",
        "src":"客户感言"
      },
      {
        "type":"txt",
        "src":'“CRA系统的特点在于它完美的糅合银企对账系统和业绩追踪系统在一起，把会计的账务处理，和财务的业绩核算融合在了一起，准确地帮助到了财务和业务，也避免了财务和业务之间大量无效的沟通。在这个过程中可以看到en+系统灵活的部署能力和柔性管理理念，这些都对我们帮助非常大。”———————— 智联招聘集团副总裁 成进先生'
      },
      {
        "type":"title",
        "src":"en+数字化"
      },
      {
        "type":"txt",
        "src":"重新定义了企业数字化转型服务"
      },
      {
        "type":"txt",
        "src":"en+数字化站在数据角度而不是专业的角度来重新定义企业数字化转型框架。"
      },
      {
        "type":"txt",
        "src":"即基于数据的全生命周期来设计方案，是基于数据流动的横向贯通的方案。专业细分能力（比如，业务，财务，人力，税务，法务）被提炼为数据处理规则，而这些规则的配置能力赋能给了企业和专家，从而建立了方案专业化和产品工具化的松耦合关系，就能够适应企业千差万别的数字化转型需求，也能聚合专家和解决方案，成为生态；"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/zhuanye1/img33.jpg")
      },
      {
        "type":"txt",
        "src":"en+数字化站在方案(Solutions)，场景(Scenarios)和工具(Software)的3S驱动的角度而不是纯工具角度来重新定义企业数字化转型的方式与过程。"
      },
      {
        "type":"txt",
        "src":"方案：是企业数字化转型的灵魂，解决企业横向服务的效率问题；"
      },
      {
        "type":"txt",
        "src":"场景：是企业数字化转型的重点，解决企业纵向服务的效率问题；"
      },
      {
        "type":"txt",
        "src":"工具：是方案与场景的载体，最终为企业数据价值最大化服务；"
      }
    ]
  },
  {
    id: "zhuanye3",
    "src": require("@/assets/images/article/zhuanye2.jpg"),
    time:"2019-02-18",
    name: "项目型企业数字化转型的方法论",
    content:[{
      "type":"img",
      "src":require("@/assets/images/article-img/zhuanye2.jpg")
    },
    {
      "type":"title",
      "src":"背景"
    },
    {
      "type":"txt",
      "src":"中国经济进入结构化调整期已经成为共识，颗粒度经济时代的到来，让小众化，定制化生产和服务逐步成为主流，倒逼企业不得不面临两个转型：一个是产品与商业模式的转型，一个是数字化转型。前者更多是企业主在业务经营层面要考虑的问题，后者是企业主要在管理层面要考虑的重要问题。"
    },
    {
      "type":"txt",
      "src":"我们遇到了越来越多的了项目驱动型的客户在数字化转型方面非常痛苦，所以我们提炼出来很多共性问题并很好的解决了这些问题。 在这里，我们和大家分享一下。"
    },
    {
      "type":"title",
      "src":"一、共性问题"
    },
    {
      "type":"txt",
      "src":"1. 项目从线索到回款的闭环，环节众多，参与方多，协同困难重重；"
    },
    {
      "type":"txt",
      "src":"2. 企业主看不到数据全貌，但是公司又养了一堆数据搬运工；"
    },
    {
      "type":"txt",
      "src":"3. 项目进度，成本费用和回款周期不可控；"
    },
    {
      "type":"txt",
      "src":"4. 项目绩效考核流于形式；"
    },
    {
      "type":"txt",
      "src":"5. 企业上了各种系统，比如CRM，OA，项目管理，预算管理等等还是没有解决企业主关心第2点问题；"
    },
    {
      "type":"title",
      "src":"二、根本问题"
    },
    {
      "type":"txt",
      "src":"造成上述那么多问题的本质原因是企业主是没有意识到让数据流动所带来的价值，也没有找到能让数据流动起来的解决方案和系统。 为了解决这个数据流动的问题，有些企业就采用了的传统的解决方案即CRM+PM+OA+财务核算系统，这种模式的弊端在于没有一个系统是站在数据流动和驱动的角度来设计的。OA的设计理念只是管流程走通，没有站在业务，财务和数据的角度来考虑问题，所以用OA来连接其他系统，不仅和业务高耦合，让系统不堪重负，最后的结局就是不断换其他系统，周而复始。所以企业投入了巨大定制化的成本和接口成本来传递数据。"
    },
    {
      "type":"title",
      "src":"解决方案"
    },
    {
      "type":"txt",
      "src":"1. 从源头解决问题，即所有的问题从项目线索开始，通过整合CRM和项目管理和财务管理的能力，修建企业数据流动的高速公路，形成数据闭环；"
    },
    {
      "type":"txt",
      "src":"2. 用最小颗粒度的字段来创建项目全生命周期管理模型能够灵活，松耦合，可扩展的满足企业千变万化的项目管理需求；"
    },
    {
      "type":"txt",
      "src":"3. 数据的连接，处理和翻译一定是流畅的，实时的，可视的；"
    },
    {
      "type":"txt",
      "src":"4. 通过事件驱动实现管理的最小颗粒度，即员工的行为，来实现来对项目的合同、发票、资金管理，成本费用进行精细化管理与协同和风险可控；"
    },
    {
      "type":"txt",
      "src":"5. 基于底层强大的数据统计与分析能力，让项目绩效考核，实时，高效，准确，有依据；"
    },
    {
      "type":"txt",
      "src":"6. 项目管理使用的第三方资源和服务可以根据场景实时调用，并形成数据；"
    },
    {
      "type":"txt",
      "src":"目前市面上能够一站式满足上述解决方案的系统，就只有en+数字化。 en+数字化在解决方案中率先提出并实现了场景即服务，服务即数据，数据即价值的理念，帮助企业实现数字化转型和企业数据价值最大化的目标。"
    }
  ]
  },
  {
    id: "zhuanye4",
    "src": require("@/assets/images/article/zhuanye3.jpg"),
    time:"2018-09-08",
    name: "对不起，您需要的即不是OA也不是ERP和BI，而是数据流动！",
    content:[{
      "type":"img",
      "src":require("@/assets/images/article-img/zhuanye3.jpg")
    },
      "有一段时间没有写文章了，最近和客户接触下来最深的感触就是如果客户早一点在观念和思想上面有所改变，就不会走这么大的弯路和花不必要的冤枉钱。所以，我觉得非常有必要把好东西分享出来，希望能够帮助到大家。",
      "第一， 客户想要的是什么",
      "当企业在成长过程中，遇到了成长的烦恼的时候，往往发现人越多效率越低下，老板看不到企业数据的全貌，经营决策就是在拍脑袋，长此以往就会陷入深深的焦虑中。这种焦虑不断传导给管理层，所以管理层急切的想解决这个问题，那么摆在老板和高管面前的选择题就是OA，ERP和BI工具，因为在他们的认知范围内，也就是这些选项了。然后大家对这三项选择题不断找厂商对比，出方案，看案例和细节，然后也陷入到了细节中，最后忘记了自己的初衷是要解决什么问题了，并且不管是哪种选择哪个，出来的结果都是鸡肋，一声叹息，因为这三个选择项，不论选择哪种都不能很好的解决企业的数据问题。",
      "第二， OA，ERP和BI的问题",
      "1） OA的问题",
      "OA发展了接近30年，是PC时代的产物，只是用来管流程，但是管不了业务和财务，对数据不能形成闭环。很多公司用OA是无奈的选择，并且会把OA改造成为连接业务和财务的中间键，OA被迫接入了无数个接口，结果整个企业在系统整合上面越做越重，做后无法动弹，只能重新规划换系统。然而后面的系统规划无非就是换了一个OA，一个财务系统，几个业务系统，仍然会再次陷入到这个怪圈里面。",
      "导致这个问题的最根本的两个原因就在于OA与系统间的结合是高耦合关系，业务的逻辑变化会导致OA重新开发，非常痛苦。还有一个原因就是OA只能管流程，不能管数据，导致数据传递不出去。",
      "2） ERP的问题",
      "ERP也是工业时代的产物，具体来说ERP的鼻祖SAP是为西门子服务起家的，带有明显的制造业的痕迹。制造业强调的是高度自动化，是弱化流程管理的。所以市面上的SAP，Oracle和国内的厂商基本是这个设计思路，即管的了业务管不了流程。而现在的企业大部分在第二和第三产业，或者是混合业态，需要强调的是协作和流程化的管理，通过流程来传递和管理数据。所以，在国内很多非制造业企业里面，特别是服务型企业，互联网企业，高科技企业等等，大部分企业用的ERP系统都沦落为一个核算系统，或者成为摆设。",
      "3） BI的问题",
      "BI的强大的分析能力和酷炫的数据可视化能力会抓住大部分决策者的眼球，也是能够让企业掏钱包的重要因素，但是冷静的想想最为关键的问题，数据从哪里来，数据是不是全面的，闭环的，可追溯的，真实的，有效的，实时的，如果上面的任何一个问题回答是No, 那么对不起，钱又浪费了。因为垃圾进，垃圾出。",
      "第三， 数据流动该如何实现",
      "当我在听到客户给我大谈OA的需求，ERP和BI的需求的时候，我一般会把客户拉回到问题的基本面，即上这些系统的初衷是什么？客户一般会回答，“降低成本，提高效率，控制风险”，这些答案都没有错，但是如果数据不流动，不是闭环，要做到上面三个目标谈何容易。",
      "所以，我经常告诉客户，您现阶段要的即不是OA，也不是ERP和BI，您要的是数据流动。只有数据流动起来，才能做到数据驱动运营，精细化管理。移动互联网，大数据时代的到来，对我们影响深远的是数据的无处不在以及数据的时效性。任何商机转瞬即逝，如果数据不流动，不能够实时做出过程控制和决策，滞后的数据给企业来的的价值就不大了。",
      "要解决数据流动的问题，那么就需要一个新的系统来实现，即要成为企业各个系统之间的连接器，翻译器和处理器的系统。",
      "首先，企业的系统碎片化是以前自己给自己挖的坑，需要通过“数据连接器”来填，当然连接器必须要以“松耦合”的方式和企业的原有的系统做融合。",
      "其次， 企业需要一个“数据翻译器”，就是一定要把业务和财务数据相互的翻译出来，即业务数据财务化，财务数据业务化，达到业财融合的效果，保持大家在不同的场景下用适当的语言来沟通，避免业务和财务经常在一起鸡同鸭讲，老板看着都醉了。记住数据分不同维度和口径，同一个业务场景，不同的数据维度，分析出来的结果是不一样的。",
      "最后，企业需要一个是“数据处理器”数据进来了，需要实时高效的处理，即需要把数据进行加工匹配，合并分解，去特殊符号，去重等等，才能保证数据是符合企业的控制和使用规则，避免垃圾进，垃圾出。",
      "说到底，企业在管理系统选型方面一定要慎重，问问清楚自己到底想要什么，不是头疼医头，脚痛医脚的做法，在大数据时代，特别是在企业走向数字化转型的迫切需求的时期，一定要找到一个能够驾驭数据，并且能够看到问题本质的厂商来帮助企业解决问题，再不要轻易陷入到上面描述的问题的怪圈里面了，花了钱打了水漂，还没有解决问题。",
      "企业要做好数字化转型，最需要先做的一件事情，就是修建数据流动的高速公路，让数据流动起来。"
    ]
  },
  {
    id:"zhuanye5",
    "src": require("@/assets/images/article/zhuanye4.jpg"),
    time:"2017-11-08",
    name: "AI下的财务发展方向",
    content:[{
      "type":"img",
      "src":require("@/assets/images/article-img/zhuanye4.jpg")
    },
"想写这篇文章很久了。 最近这些概念炒的很火，外行看热闹，内行看门道。我想站在内行角度帮助外行和同行做出理解这三个概念的正确姿势。",
"关于对财务机器人的理解：",
"先说德勤引爆的四大事务所的财务机器人，感觉很酷炫，很科幻，外界以为财务会被机器人取代了。这种线性的思维，做为不了解财务的人来说可以理解。的确，记账这个事情是简单和标准化的劳动。“财务即IT”这个观点也广受认可。但是冷静下来想想，目前的这些财务机器人实现的功能酷炫成分大于真实的价值。 财务机器人无非是让记录价值这件事情高效化了，也让财务人员从底层的事务中解脱出来，去做更有价值的业务财务和战略财务的事情，这个是值得肯定的。然后仔细想想，当我们处在从IT（信息技术）时代向DT（数据技术）时代转变的时候，不仅仅是提高记录价值的效率就够了。节省低端的财务人员并不是企业关注的重点，而怎么把业财融合，把数据价值最大化才是企业在DT时代关注的重点。 在做好业财融合的同时，裁掉低端财务人员是个自然而然的事情，千万不要本末倒置。",
"四大机器人的真实意图在于审计的全量化而非抽样化，降低他们对大型企业的审计成本，也是对数据的价值变现吧。 所以人家站在审计的角度，把企业做不好或者做不到的事情从这简单点切入进来了。但是这个立场还是不一样的。企业还是要关注自己的核心问题到底是记账效率还是业财融合不完善和不充分。请参看笔者的《数字化时代下财务工作的核心问题》",
"大家都愿意去做简单易懂，能够马上见成效的事情，而这样的事情，一般只是解决表象问题。如同市面上很多报销类的SaaS软件一样，没有搞清楚为什么会有报销问题存在，仅仅提升一个事后处理的效率，没有去报销化，没有控制企业的财务黑洞，解决方案都是流于形式。",
"关于对财务共享中心的理解：",
"首先财务共享中心是工业时代和PC时代的产物，那个时候中心化，集约化是最适应那在那个时代发展的产物。财务共享中心在中国也发展了10余年，真正做好做出效果的企业并不多。然而在这10多年，科技已经迭代了好几轮，已经从PC时代过渡到移动互联网时代，马上进入物联网时代或者DT时代，财务共享中心的几个不适应的问题就会凸显出来；",
"1.中心化思维与移动互联网时代的不契合：",
"移动互联网时代的标志就是共享，共赢和去中心化和去中介化。注意这里的共享是指数据与信息的共享而不 是财务共享中心里面的职能共享，业务处理能力共享。去中心化就是组织形态的变革，要跨职能和阿米巴化，中 心化的思维不会适应新兴组织形态的要求，反而成为了掣肘和瓶颈。应该把决策权交给能够“听见炮声”的团队。",
"2.统一和规范的财务数据处理是否一定需要集中：",
"在工业时代和PC时代，互联网技术和数据自动化处理能力没有像当前那么发达的时候，这种财务共享中心的 做法是符合当时技术环境和时代需求的。 而现在的科技已经能够根据场景与技术的结合，智能化实时按照预设好 的规则进行标准化处理的时候，再去做财务共享中心的集中化处理，显得没有和当前时代的技术融合起来。 企业目 前要做到财务记账和处理规范统一，重心是放在规则和流程的梳理，做好场景的预设和判断，然后智能化的业财一 体化系统能够根据场景和预设规则自动处理，并且自动记账，那么上述的财务机器人和财务共享中心所想解决的问 题都一并解决了。而不是，现有企业的解决方案估计会是上一个财务共享中心和财务机器人。看起来很好和实际的 效果，这个大家在对比一下数字化的理解，就能够有清晰的判断了。",
"关于对数字化的理解：",
"数字化，最关键的要素是一定要把管理会计的专业能力和DT时代的大数据和互联网技术结合起来，帮助企业管 理者和财务工作者实现从事后的记录价值到实时的创造价值的转变。 那么最关键的一步就是先帮助企业做到业财融 合。没有这个基础，那么管理会计的理论和方法仍然停留在方案和纸面上。",
"企业成功经营和管理一定是先终后始，先算后做；数字化是把管理会计的无边界的定义用技术的无限扩展的能力 实现出来。因为企业管理问题多种多样，从成本到绩效，从人员到激励，最终的运营数据的最终反映是财务数据。财务 数据的事后价值是远远小于财务数据的规划价值，预测价值，过程管理的分析价值。",
"所以数字化解决问题的着眼点一定是要和业务融合起来，在业务的源头把可能需要控制的风险，需要提供的财务 支持；需要提升的效率，需要留下的数据多维度标签，需要设定的场景都提前规划好，设计好，然后让技术来按照这些规划来追踪，判断，控制，分析，记录。这样的好处是原来企业飘着各种表象级的问题，通过数字化的解决方案，抓住源头与根源，连根拔起式的方式，让企业的业绩和效率最大化的提升。请参看笔者的《en+数字化如何提升上市企业市值管理水平》。",
"最后，从数字化到智慧财务的迭代：",
"人工智能的发展，会让数字化走向智慧财务，智慧涵盖了对数据的处理，自然语义的判断，人工智能的思考和方案的推荐，但是最终的目的都是为企业提供有价值的经营决策支持的数据。技术和时代不断的在变化，但是财务的本质并没有改变。"
    ]
  },
  {
    id: "zhuanye6",
    "src": require("@/assets/images/article/zhuanye5.jpg"),
    time:"2017-10-22",
    name: "数字化时代下财务工作的核心问题",
    content:[{
      "type":"img",
      "src":require("@/assets/images/article-img/zhuanye5.jpg")
    },"中国的财务的发展历程，从经历了算盘时代，PC时代，大数据和云计算时代并逐渐步入人工智能时代，财务工作的核心问题不再是从原来不断满足粗放式经营和管理环境中的记录价值的需求，而是变为企业精细化管理的数字化需要和业财融合不充分不完善之间的问题。",
"在大数据和云计算时代，企业面临的竞争是如何将专业领域的创新与技术的创新充分的结合起来，不仅仅开拓新的市场，而且要从存量市场挖掘出新的机会。企业决策者越来越依赖有价值的数据做分析，决策和控制企业风险，而这些都需要将精细化管理中的过程数字化和业务与财务融合化，并站在管理会计的角度对所产生的数据进行进一步加工和处理。这个数字化进程将随着科技的发展越来越迅速。",
"就目前我国财务工作的现状而言，上述的数字化和业财融合化的进程才刚刚起步，笔者访谈了众多企业的财务负责人，无一例外的认同目前财务工作最大的痛苦没有之一，就是不得不把核算系统当做管理会计系统来用。核算系统只能按照财务会计准则从一个维度去记录企业的经营活动情况，而企业经营管理的需求是需要多维度和多视角的分析数据。这个就是问题的根源，对数据要求和处理方式不同。",
"同时企业的数据是碎片化的，当财务人员要获取各项数据做管理会计分析的时候，需要从各个系统收集和整理数据，然后才是分析和预测。而获取数据和整理数据耗费了财务人员大量的时间，并且还是滞后的数据。",
"同时没有数据闭环，没有多维度的数据标签，也很难站在多角度去分析。随着第三产业和科技的迅猛发展，传统的基于工业企业时代的管理会计分析模型也不适应各种组织形态和商业模式的变化。传统的杜邦分析，量本利分析，成本管理很难为得出“好的亏损或者坏的利润”的判断。",
"要解决上述的财务工作的核心问题，除了需要很好的管理会计的方案，也需要优秀的性价比高的管理会计的落地工具。智能化，SaaS化与管理会计的融合的工具将是企业在数字化时代下的首选工具。"]
  },
  {
    id:"zhuanye7",
    "src": require("@/assets/images/article/zhuanye7.jpg"),
    time:"2017-08-31",
    name: "数据金融化",
    content:[{
      "type":"img",
      "src":require("@/assets/images/article-img/zhuanye7.jpg")
    },
    "当大家都在讨论数据价值的时候，那么下一个问题就是如何让数据价值变现?",
    "一般来说，数据价值变现可以有直接方式或者间接方式来实现。本文探讨的一个数据价值变现的混合模式：数据金融化。",
    "数据金融化来源于几个朋友和我在商业模式中的探讨，很受启发。数据金融化基本定义为企业可以把自身真实的上下游交易数据授权给金融机构，金融机构可以有选择性对企业业务周期的不同环节提供各种类型的金融服务，包括授信，保理，保险等等。",
    "在传统融资模式下，企业要主动向银行或者金融机构提供资料，等待各种审批和风控，导致手续繁冗，效率低下。同时由于信息不对称，传统金融机构也对企业提交过来的数据的真实性，有效性无法做出多维度的准确的判断，导致资金需求方和资金供给方合作效率低下，体验相当痛苦。",
    "而数据金融化的模式的方向恰恰和传统模式相反，是金融机构主动根据企业授权数据，通过量化因素和非量化因素综合分析，主动为企业提供金融服务，才能够最大化发挥金融产品的价值。",
    "数据金融化在企业和金融机构之间形成了一个良性循环和多赢局面。数据金融化不仅仅是在借贷或者流转中产生价值，而应该是在挖掘企业数据中发现企业的整体价值。金融机构应该根据每个企业不同的需求推出定制化的金融产品，真正做到金融产品小、快、灵的满足企业融资需求，帮助企业最大化提升企业盈利能力；并且在可控的风险范围内提升金融产品的周转率和金融产品的价值最大化。",
    "数据金融化是未来金融机构和实体企业的更紧密和更高效的合作模式。英富莱很看好这个方向和趋势，并且愿意在这个趋势里面为企业和金融结构提供更好的服务。"]
  },
  {
    id: "zhuanye8",
    "src": require("@/assets/images/article/zhuanye8.jpg"),
    time:"2017-03-12",
    name: "CFIO ，大数据时代下CFO的新职位",
    content:[{
      "type":"img",
      "src":require("@/assets/images/article-img/zhuanye8.jpg")
    },
    "CFIO， 即 CFO （首席财务官）和 CIO （首席信息官）的合并称谓。 在云计算和大数据突飞猛进的时代，企业运营数据和财务数据越来越紧密相关。CFO们将面对整个企业的运营系统和财务系统的整合挑战。未来企业的数据价值将是企业的核心资产，CFO不仅仅要考虑资产负债表内的资产，而更需要考虑数据的价值挖掘和变现，实现企业收益最大化。",
    "CFIO是对企业管理层跨界的要求，也是大数据时代对这个新的岗位的要求。传统的CFO需要往前走一步，即要成为懂得商业语言的专家（财务+金融），也要成为系统整合和数据分析的专家，为企业打造一个业务财务一体化的系统，帮助企业降低成本，提高效率和控制风险，在节流的战略高度层面，帮助企业提高盈利能力。"]
  }
]
const canhuiList=[
  {
    id: "canhui1",
    "src": require("@/assets/images/article/canhui1.jpg"),
    time:"2021-06-15",
    name: "en+数字化助力第六届中国企业财务管理创新与发展论坛",
    content:[
      {
        "type":"txt",
        "src":"6月11日，由中国企业财务管理协会举办的第六届“中国企业财务管理创新与发展论坛”在北京京都信苑饭店召开。作为一年一度的财务行业智慧创新盛宴，本届论坛紧紧围绕“新发展·新财务”这一主题，聚集来自政、企、学界的嘉宾，分享交流在大数据、智能时代下的财务管理的创新发展，以及财务人员在数字化时代面临的挑战和重新定位。 "
      },
      {
        "type":"title",
        "src":"“突破创新”：财务管理迎来数字化转型关键时刻"
      },
      {
        "type":"txt",
        "src":"自2020年“新冠疫情”出现以来，企业的线下业务快速向线上转移，经营模式快速进入业务数字化和智能化阶段，财务人员的数字化转型需求更加迫切。聚焦“新技术时代下的财务人员的创新与转型”，参与论坛的嘉宾们分享了精彩的观点。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui1/img1.jpg")
      },
      {
        "type":"title",
        "src":"十一届全国政协副主席、中国企业财务管理协会创始会长李金华"
      },
      {
        "type":"txt",
        "src":"十一届全国政协副主席、中国企业财务管理协会创始会长李金华为会议开幕致辞，李主席指出，希望通过论坛研讨，深入探求在新发展背景下的财务管理新思路、新模式、技术途径和办法，从而推动中国企业财务管理创新，继而推进中国企业的高质量发展。李主席还为评选出的16名2020年全国企业类财务管理领军人才颁发了证书，并合影留念。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui1/img2.jpg")
      },
      {
        "type":"title",
        "src":"中国企业财务管理协会会长李永延"
      },
      {
        "type":"txt",
        "src":"中国企业财务管理协会会长李永延分享了近期在全国市场调研的最新思考与观点，指出企业经营的新竞争力正在发生显著的变化，以价值、文化和艺术层面的经营方式的变革为核心，从吃、穿、住、行等多个成功企业的商业模式、供应链条和产品模式产生的变化，围绕提供个性化价值、构建价值闭环等方面发生的变革做了阐述。同时，李会长提出，在企业转型的过程中，财务人员的价值会越来越大，但财务人员需要积极面对大数据等技术带来的转型挑战，多学习新技术、新知识、新方法，成为企业转型的引领者，形成企业竞争的新财务竞争力，为企业创造更大的价值。 "
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui1/img3.jpg")
      },
      {
        "type":"title",
        "src":"中国石油集团公司总会计师刘跃珍"
      },
      {
        "type":"txt",
        "src":"中国石油集团公司总会计师刘跃珍围绕“变与应变”的主题，分享交流了中国石油集团财务团队如何应对环境变化带给企业的挑战。由于企业利润和油价强相关，加上中石油的超大企业规模，对财务管理人员提出了更高要求。作为应对，中石油建立了多个数字化平台，包括业财融合价值管理平台、全球财务共享服务平台等。借助这些平台，财务人员在推动企业业财融合中发挥重要作用，打赢低油价攻坚保卫战；财务共享平台将两万余财务人员的工作批量化、集约化到不到两千人的财务共享中心完成，带来大幅的效率提升，并实现低碳、安全运营。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui1/img4.jpg")
      },
      {
        "type":"title",
        "src":"浪潮集团首席财务专家陈琳"
      },
      {
        "type":"txt",
        "src":"浪潮集团首席财务专家陈琳分享了财务数字化转型的本质、新风向和企业应对策略。陈琳认为，数字化是财务转型的第一驱动力，可以帮助新财务实现落地和带来实效；财务工作已经不再局限在传统的业务范围，而是跨越财务和业务之间的界限，甚至跨越企业和行业的边界，向外部提供服务。"
      },
      {
        "type":"txt",
        "src":"中央财经大学财政税务学院副院长何杨、中科讯联智慧网络科技（北京）有限公司董事长张睿围绕国际国内的税收管理的新挑战和新格局展开了主题演讲。我国数字基础、数字治理、主体活力、民众参与度等指标在疫情期间均出现大幅上升，企业数字化转型加速。专家们指出，数字经济对国际国内税收既带来挑战也带来了变革中的机遇，财税数字时代全面来临。"
      },
      {
        "type":"txt",
        "src":"北京工商大学原副校长、会计名家谢志华，中财协副秘书长张继德围绕财务管理在新时代的嬗变、财务管理的新技术和新价值等角度进行了演讲分享。现阶段会计只是反应核算场景而没有反应业务场景，这也是业财不能融合的根本原因。业财融合的本质是一切业务都要创造价值。“上云用数赋智”等技术大力推动了企业业财融合。数字技术推动了价值创造，这也是财务数字化转型的本质。"
      },
      {
        "type":"title",
        "src":"“数字化审计师”发布，助力财务人员找准新定位"
      },
      {
        "type":"txt",
        "src":"本次论坛的另一大亮点，是“数字化审计师”项目的正式发布。"
      },
      {
        "type":"txt",
        "src":"作为推动财务人员数字化转型的重要项目，中国企业财务管理协会人才培养管理部（原商学院）筹备三年之久的“数字化审计师”项目在“智能时代财务人才的培养与创新实践”分论坛上隆重推出。"
      },
      {
        "type":"txt",
        "src":"中国企业财务管理协会审计人才评价专业委员会主任、“数字化审计师”教材主编、南京审计大学陈丹萍教授代表项目研发组，向与会嘉宾介绍了“数字化审计师”项目的研发背景、历程、内容、价值和应用。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui1/img5.jpg")
      },
      {
        "type":"title",
        "src":"南京审计大学陈丹萍教授"
      },
      {
        "type":"txt",
        "src":"陈丹萍教授介绍，“数字化审计师”项目致力于深入解析企业内部审计在大数据环境下的脱变与应对，将审计专业知识与信息化技术在企业实践中的实际应用有机结合，着力于培养能够了解和应对数字化审计环境、创新审计流程、提升审计工作效率的新一代数字化审计人才。"
      },
      {
        "type":"txt",
        "src":"自2017年开始，中国企业财务管理协会会长李永延、副会长刘运就带队深入中石化等13家央企做调研，致力挖掘新技术环境下企业财务人员的用人需求和培养路径。"
      },
      {
        "type":"txt",
        "src":"2019年年底以来，中国企业财务管理协会已推出“智能财务管理师”、“智能税务师”、“智能风控师”等智能系列培训项目，“数字化审计师”的推出为这一智能系列项目再添重磅内容。"
      },
      {
        "type":"txt",
        "src":"智能系列的培训项目面世仅一年有余，就已取得颇多成绩，例如，“智能财务管理师”被纳入人社部2020年知识更新工程；“智能税务师”在财务人员中广受认可，数千人报名参与了课程的学习并取得证书。"
      },
      {
        "type":"txt",
        "src":"据了解，中国企业财务管理协会的智能系列财务人才培养项目将持续更新，与技术发展和企业需求密切结合，不断推动企业财务人员向新财务的转型。"
      },
      {
        "type":"txt",
        "src":"（更多关于“数字化审计师”的内容，可扫码下方二维码，关注“中财协人才培养管理部”微信公众号获得）"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui1/img6.jpg")
      },
      {
        "type":"txt",
        "src":"附：分论坛简报"
      },
      {
        "type":"title",
        "src":"“智能时代财务人才的培养与创新实践”分论坛"
      },
      {
        "type":"txt",
        "src":"由中国企业财务管理协会人才培养管理部（原商学院）主办并承办的“智能时代的财务人才培养和创新实践”分论坛，邀请到中国企业财务管理协会会长李永延、中国企业财务管理协会副会长刘运、中国企业财务管理协会特聘副会长王开田、浪潮集团首席财务专家陈琳、南京审计大学教授陈丹萍、知名税务专家夏晨华、en+数字化创始人兼CEO曾亮等嘉宾出席。"
      },
      {
        "type":"txt",
        "src":"李永延会长作了“智能时代财务人才培养的四个问题”的主题发言，提出智能时代财务人员需要积极学习和探索，避免被边缘化，同时发挥更大的作用。并指出，智能时代中国企业财务管理协会培养数据智能人才的方法是评价。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui1/img7.jpg")
      },
      {
        "type":"title",
        "src":"中国企业财务管理协会会长李永延"
      },
      {
        "type":"txt",
        "src":"刘运副会长作了“智能时代财务人才培养和创新实践”的主题分享，结合自身几十年的央企财务管理工作经验，分析了新经济、技术形势下财务人员面临的挑战，并介绍了中国企业财务管理协会人才培养管理部（原商学院）在财务人才培养方面做出的努力、探索与成绩。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui1/img8.jpg")
      },
      {
        "type":"title",
        "src":"中国企业财务管理协会副会长刘运"
      },
      {
        "type":"txt",
        "src":"分论坛上正式发布了“数字化审计师”人才职业能力评价项目，并进行了“数字化审计师”的项目合作授权签约仪式，中国企业财务管理协会常务副秘书长、原商学院执行院长胡芳与两家授权机构的代表现场进行了项目招生授权协议签署。在“学习基地授牌仪式”中，北京、河南、山西等八家学习基地的代表上台接受刘运副会长颁发的学习基地牌匾。"
      },
      {
        "type":"txt",
        "src":" 分论坛的最后环节是主题为“大咖面对面-数字化转型与业财融合”的圆桌论坛，邀请了刘运副会长、陈琳、夏晨华、曾亮四位专家回答在场观众的提问，现场观众互动踊跃，嘉宾回答精彩纷呈，铸就了一场财务智慧的盛宴。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui1/img9.jpg")
      },
      {
        "type":"title",
        "src":"从左至右分别为：刘运、陈琳、夏晨华、曾亮"
      },
      {
        "type":"title",
        "src":"“数智化时代财税服务产业的创新发展”分论坛"
      },
      {
        "type":"txt",
        "src":"由中国企业财务管理协会代理记账专业委员会承办的“数智化时代财税服务产业的创新发展”分论坛，通过结合国际国内先进经验与中国代账行业实践，深度融合行业人才培养、信息技术及企业财务管理服务等领域，探讨中国代账行业产业化发展的新趋势，来为中国代账行业以及中小企业的持续健康发展注入新思路。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui1/img10.jpg")
      },
      {
        "type":"title",
        "src":"从左往右依次是：中国企业财务管理协会副会长兼秘书长郑述飞、代账专委会会长黄川、代账专委会副会长兼秘书长张国伍"
      },
      {
        "type":"txt",
        "src":"中国企业财务管理协会副会长兼秘书长郑述飞、中国企业财务管理协会代理记账专业委员会会长黄川、中国企业财务管理协会代理记账专业委员会副会长兼秘书长张国伍分别致辞。同时，在与会嘉宾的共同见证下，中国企业财务管理协会代理记账专业委员会正式成立。"
      },
      {
        "type":"title",
        "src":"“打造雇主认可的智能财务职业能力评价”分论坛"
      },
      {
        "type":"txt",
        "src":"由APOSEC北京国财人才开发有限公司承办的“打造雇主认可的智能财务职业能力评价”分论坛聚焦职业能力评价的主题，邀请了《财务管理研究》杂志社社长李鸿、中国企业财务管理协会人才培养管理部财务专家曾亮、中国社会科学院研究生院会计博士宋佳、中国亚洲经济发展协会职业人才委执行会长李宇等嘉宾参与话题的讨论。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui1/img11.jpg")
      },
      {
        "type":"title",
        "src":"五位嘉宾参与分论坛的研讨交流"
      },
      {
        "type":"txt",
        "src":"研讨中，嘉宾们深入浅出的对许多具体问题展开交流分享，如雇主认可的职业能力评价体系要达到哪些标准、目前雇主为什么还执念于国家职业资格、怎么吸引企业参加雇主认可计划、采取什么方式可以提升智能财务评价体系的雇主认可度等，精彩的内容赢得现场阵阵掌声。"
      }
    ]
  },
  {
    id: "canhui2",
    "src": require("@/assets/images/article/canhui2.jpg"),
    time:"2019-10-30",
    name: "大成昆明“业务一体化系统”项目启动会圆满举行",
    content:[
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui2/img1.jpg")
      },
      {
        "type":"txt",
        "src":"2019年10月28日，北京大成（昆明）律师事务所在事务所举行“业务一体化系统”项目启动会。北京大成（昆明）律师事务所马巍主任、湖南英富莱公司CEO曾亮及双方项目组成员参与此次项目启动会。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui2/img2.jpg")
      },
      {
        "type":"txt",
        "src":"马巍主任表示，业务数字化管理系统的特点是把人（律师、当事人）、组织（律所、律师团队）、事件（业务项目流程）、组织管理（财务管理、运营支持）有机结合起来，将对律师、律所的发展起重大促进作用。同时，基于大成昆明办公室多年来积累的丰富管理经验及精细化管理水平和湖南英富莱公司拥有的专业技术团队，大成昆明办公室对该业务数字化管理软件开发寄予厚望，将来，希望双方加深合作，逐步开发系列法律科技产品，为律师、律所、法律服务行业的发展作出贡献。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui2/img3.jpg")
      },
      {
        "type":"txt",
        "src":"英富莱公司CEO曾亮表示，我们非常愿意与大成昆明办公室强强联合开拓新的领域，把这个项目做好，第一步我们需要把基础打好，根据大成昆明办公室的实际情况搭建可视化、动态化、共享化的系统，并兼顾控制与效率、数据流动与价值闭环，对项目组提出数据采集更便捷、人的参与更少、数据流动更快三个要求。作为一个赋能化、生态化的平台将为大成昆明办公室数字化转型服务提供强大技术支持，帮助律所更好地降本增效。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui2/img4.jpg")
      },
      {
        "type":"txt",
        "src":"启动会现场，还举行了由大成昆明办公室马巍主任、英富莱公司CEO曾亮对项目经理的任命仪式，任命大成昆明办公室王川荣、英富莱网络何华军为项目经理。本次会议，双方还就大成昆明办公室“业务一体化系统”项目相关细节再次进行了深入讨论。"
      }]
  },
  {
    id: "canhui3",
    "src": require("@/assets/images/article/canhui3.jpg"),
    time:"2019-09-23",
    name: "en+数字化赋能业财融合和数字化转型",
    content:[
      {
        "type":"txt",
        "src":"9月21日，由优财(中国)CMA培训中心携手美国管理会计师协会(IMA)联合主办的2019中国管理会计实践论坛在深圳金蝶软件园隆重举行。"
      },
      {
        "type":"txt",
        "src":"金秋九月，相遇鹏城。论坛会场迎来了各大企业的财务总监、财务高管、优财学员以及权威学者、专家大咖等共计400余人，在管理会计的时代大潮中，呈现了一场行业内最高规格的管理会计交流盛会。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui3/img1.jpg")
      },
      {
        "type":"txt",
        "src":"本次论坛围绕“聚焦科技赋能·赢在管理会计”这个主题，共商财务信息化时代企业和财务人如何突围；聚焦中国优秀企业的最佳管理会计实践案例，助力中国企业强化内部经营管理、提高经济效益。"
      },
      {
        "type":"txt",
        "src":"本次大会特别邀请到了美国管理会计师协会IMA市场总监echo女士，深圳注册会计师协会秘书长刘雪生先生，广东财经大学原校长、广东管理会计师协会名誉会长王华先生，深圳福田会计学会副秘书长郭玉先生，深圳大学特聘教授、财务金融研究中心主任李东辉先生，金蝶行业资讯部副总监、资深财务管理专家刘明山先生，en+数字化CEO曾亮先生，原华为某区部CFO钟利荣先生，南京云账房网络科技有限公司副总裁许泽峰先生，浙江大华技术股份有限公司副总裁魏美钟先生，世纪睿科控股有限公司执行董事兼首席运营官林沛榕先生，前海梧桐并购基金投资管理公司总经理/CMA于钦芳先生，广州宝祺来科技有限公司财务总监/CMA刘燕女士和优财（中国）CMA培训中心总经理张敏女士等十几位重量级嘉宾参加。"
      },
      {
        "type":"txt",
        "src":"会场内座无虚席，气氛爆棚！与此同时，通过网络全程参与现场直播的人数累计突破8万人！行业首例，盛况空前！"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui3/img2.jpg")
      },
      {
        "type":"txt",
        "src":"论坛开始后，首先由echo、刘雪生、王华三位嘉宾分别致开幕词，之后由李东辉、刘明山、曾亮、钟利荣、许泽峰、魏美钟共六位大咖给大家带来了精彩分享；压轴的圆桌论坛围绕“科技赋能下财务如何引领变革”的主题展开了激烈的思想交锋。思维提升与干货满满的8小时，给参会人员呈上了一场财务界的饕餮盛宴，引来好评如潮！"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui3/img3.jpg")
      },
      {
        "type":"title",
        "src":"IMA协会市场总监echo女士致开幕词"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui3/img4.jpg")
      },
      {
        "type":"txt",
        "src":"echo表示，今年是IMA协会成立一百周年，也是IMA进入中国十周年，很荣幸我本人也是CMA大家庭中的一员，在此我代表所有IMA会员，向协会表示祝贺！同时优财作为CMA首家大中华区授权的机构，也迎来了十岁的生日，未来优财将和CMA协会共同进步、共创辉煌，为国家和企业培养更多的优秀管理会计人才，帮助更多的中国财务人实现职业发展的华丽转身。"
      },
      {
        "type":"title",
        "src":"深圳注册会计师协会秘书长刘雪生先生致开幕词"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui3/img5.jpg")
      },
      {
        "type":"txt",
        "src":"他还表示，人工智能、大数据、区块链等现代技术对会计的影响是非常大的。比如审计里面有些花费大量人工成本的工作如存货盘点、测试等，都会消失掉的，尤其现在税务从金税三期出来以后，有些工作用现在的科技手段完全可以省略掉。所以管理者要应付这些挑战，管理会计一定要适应现代科技的发展。"

      },
      {
        "type":"txt",
        "src":"刘雪生先生讲到，管理会计确确实实是我们未来会计的一个方向，它主要是对内为管理层、为董事长、为总裁提供决策支持，跟财务会计向外、面向投资人提供的信息完全是两回事。所以管理会计尽管发展得比较迟，但是它对企业来说是很需要的，它可以为企业创造价值。"
      },
      {
        "type":"title",
        "src":"广东财经大学原校长、广东管理会计师协会名誉会长王华先生开幕致辞"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui3/img6.jpg")
      },
      {
        "type":"txt",
        "src":"王华先生表示，传统的会计及财务管理的职能在发生转型，在信息技术飞速发展、互联网技术日新月异的当下，AI技术普遍应用，对会计核算、金融技术、民生政务、商务服务、企业智能制造等诸多领域的功能替代趋势越来越明显。企业的业财融合、管理和战略融合、跨界知识融合的要求越来越多。从经营和业务角度，站在管理知识角度去认识、发展、开掘和应用财务功能，成为会计时尚和未来的趋势，而管理会计则代表了这种时尚和趋势。"
      },
      {
        "type":"txt",
        "src":"在现场400多位参会人员和线上8万多人的共同见证下，隆重举行了优财CMA案例式2.0（新纲）课程发布仪式。"
      },
      {
        "type":"txt",
        "src":"IMA市场总监Echo女士，深圳注册会计师协会秘书长刘雪生先生，深圳福田会计学会副秘书长郭玉先生，广东财经大学原校长、广东省管理会计师协会名誉会长王华先生、优财（中国）CMA培训中心总经理张敏女士一起上台，共同完成发布仪式，意味着优财CMA案例式课程2.0时代盛大开启！"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui3/img7.jpg")
      },
      {
        "type":"title",
        "src":"接下来，深圳大学特聘教授、财务金融研究中心主任李东辉先生就“财务人员转型的几点思考”这个话题，围绕传统财务人员目前面临的困境以及探讨这些问题的出路两大方面进行了精彩演讲。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui3/img8.jpg")
      },
      {
        "type":"txt",
        "src":"他表示，未来十年，世界经济增速减缓、结构转型、竞争加剧等因素，将会使会计人才发生结构性变化，截止2018年底，在时代洪流中，2000多万财务人员面临转型。而目前财务人员面临的困难有：难以从CEO角度全视角看问题；财务信息化导致传统的会计理念与数据收集分析方式改变；战略财务思维意识和水平不够，不敢承担高级管理职责。"
      },
      {
        "type":"txt",
        "src":"接下来李教授为财务人员的出路提供了几点建议：首先要转变思维；其次要完善知识结构，培训管理技能，加强数据分析能力的培训；最后要熟悉本公司的业务盈利模式。而转型管理会计是财务人员的最佳选择：管理会计从业务前端开始，针对企业生产经营各环节进行财务分析，融合财务与业务，运用各种管理工具和方法，协助业务对标、管理风险、激励和服务业务，达成经营目标。"
      },
      {
        "type":"title",
        "src":"金蝶行业资讯部副总监、资深财务管理专家刘明山先生向参会人员分享了关于“数字化，打造财务管理新世界”的一些思考和智慧。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui3/img9.jpg")
      },
      {
        "type":"txt",
        "src":"刘明山先生表示，传统财务管理面临挑战，财务转型势在必行。而在科技与财务融合带来时代的变革中，未来的财务发展将是“无人会计、人人财务”。"
      },
      {
        "type":"txt",
        "src":"“无人会计”是指，科技将解放财务，让财务能融入业务；重复性、规则性的会计核算过程用软件机器人取代；基于数据全连接，完成财务数据的全自动收集与核对；准确性与效率大幅提升，基础财务工作不需人工参与。“人人财务”意味着激活个体，人人皆财务；每个财务人员，利用数据服务于每个非财务人员；每个非财务人员，可以实时、准确、便捷获得个人价值相关数据，激发自我；未来人人都是财务数据的提供者、使用者、管理者。"
      },
      {
        "type":"title",
        "src":"en+数字化CEO曾亮先生就“用业财融合的方式帮助企业数字化转型-CMA实战篇”这个主题进行了精彩分享。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui3/img10.jpg")
      },
      {
        "type":"txt",
        "src":"曾亮先生表示，CMA的发展路径是对应的，在信息化时代你就是一个传统的注册管理会计师CMA；在数字化时代你是个数字化转型顾问，CMA应该站在外部顾问的角度去看待企业的问题，否则出发点永远是在财务角度看待问题，会受到挑战；再往后到延伸到人工智能化的时候，CMA的职责应该是一个数据价值管理师。"
      },
      {
        "type":"txt",
        "src":"因为管理会计最终的核心是数据价值管理，因为你把所有的结构化、非结构化数据、量化和非量化数据最终去量化，给出一个结论，最后数据驱动运营、数据驱动转型，所以你的目标是数据价值最大化、数据价值的管理。"
      },
      {
        "type":"txt",
        "src":"下午的会议内容主要聚焦在中国最佳管理会计案例实践，精彩纷呈。"
      },
      {
        "type":"title",
        "src":"首先由原华为某区部CFO钟利荣先生带来了“管理会计实践之华为业财融合案例分享”的精彩分享。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui3/img11.jpg")
      },
      {
        "type":"txt",
        "src":"钟利荣先生讲到，华为把财务的整个工作分成三块，第一块是专业能力方面的。第二是面向业务的，比方说计划预算预测、产品财务管理、客户及销售财务管理、合同交付财务管理。第三是流程IT基础平台。"
      },
      {
        "type":"txt",
        "src":"华为对财务人员的要求，特别是对管理者的要求是，第一，财务只有懂业务，才能真正成为业务的助手。所有财务人员进公司以后都是要去学业务的，到站点上去锻炼；第二，财务人员要懂项目管理，项目是华为最基本的细胞，它融入了所有的管理方式，所以必须要有项目管理理念，任何一个CFO必须要有项目管理经验才能提上去；第三，财经队伍要加强混凝土建设，把业务人员引入财务里面来，激活财务组织，让业务的人懂财务；最后是财务管理一定要坚持流程化、职业化，否则就是高成本。"
      },
      {
        "type":"title",
        "src":"南京云帐房网络科技有限公司副总裁许泽峰先生带来了“管理会计实践之云账房中小企业业财融合案例分享”的主题演讲。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui3/img12.jpg")
      },
      {
        "type":"txt",
        "src":"许泽峰先生表示，这一轮企业或者财务的变化，一方面是内在的，源于企业业务发展的需要、国际化的需要、经营的需要，但是从云帐房的角度来看，造成这一轮财务变化有一个很重要的外部因素，就是税务的变化。"
      },
      {
        "type":"txt",
        "src":"因为现在税务的手段已经渗透到企业经营情况的方方面面，社保、个税划归税务局征缴之后，在所得税中间所腾挪的空间也少了，银行的流水达到一定金额之后无论是对公还是对私都会向税务局同步，无所遁形。所以他认为这一轮财务的变化或财务管理的提升有一个重要的外部因素就来自于税务和政策的变化。"
      },
      {
        "type":"title",
        "src":"浙江大华技术股份有限公司 副总裁魏美钟先生做了“基于生态链的全球数字财务与人工智能”的精彩分享。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui3/img13.jpg")
      },
      {
        "type":"txt",
        "src":"魏美钟先生表示，做管理会计很重要的是要有数据，没有数据管理会计巧妇难为无米之炊。我们的财务数据一定是从业务端来的，业务端又从生态伙伴那里来的，这所有的数据全部打通，然后最终汇集到财务，对财务来说，财务就可以真正做经营分析。"
      },
      {
        "type":"txt",
        "src":"接下来魏总用幽默的语言讲解了大华公司基于生态链的数字财务和人工智能方面所做的探索和努力。魏总讲到，“当前从IT基础化的角度看，一个是互联网，移动化、轻型化、云化，基础设施非常好。还有就是数据，数字化、生态化、海量化也很不错。从管理来说，这些基础管理就可以从标准化入手，标准化做好了信息化很容易，信息化做好了自动化，自动化做好了智能化，这是一个自然的发展过程，我觉得这个时代已经到来了。”"
      },
      {
        "type":"txt",
        "src":"最后压轴的圆桌论坛环节彻底引爆了今天的论坛氛围，引来参会人员和线上观众好评如潮，大呼过瘾！在广州宝祺来科技有限公司财务总监刘燕女士的主持下，曾亮、林沛榕、于钦芳、魏美钟四位大咖围绕“科技赋能下财务如何引领变革”的主题思想交锋，分享真知灼见，互动环节参会人员纷纷争相提问，学习热情势不可挡！"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui3/img14.jpg")
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui3/img15.jpg")
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui3/img16.jpg")
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui3/img17.jpg")
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui3/img18.jpg")
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui3/img19.jpg")
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui3/img20.jpg")
      },
      {
        "type":"txt",
        "src":"会中，优财还为毕业学员举行了颁证典礼，到场的近百名优财新鲜出炉的CMAer纷纷上台，接受了来自现场400位及在线8万余名参会人员共同见证下的祝福和嘉奖。"
      },
      {
        "type":"txt",
        "src":"优财在专注CMA的十年间，每年都会为毕业学员在三个考季结束后举办大型的颁证典礼，以嘉奖学员取得的好成绩。至此，优财在全国范围内已经成功举办31届颁证典礼，覆盖学员上万人次。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui3/img21.jpg")
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui3/img22.jpg")
      },
      {
        "type":"txt",
        "src":"在大智移云物时代，传统的财务会计工作正面临严峻挑战，而管理会计作为新一代财务管理者，却助推着企业价值的创造和提升。因此，作为全球认可的权威管理会计师认证，CMA已经成为新一代财务管理者的最佳选择！"
      },
      {
        "type":"title",
        "src":"自2009年成为IMA中国首家授权的CMA培训机构至今，优财在促动CMA项目在中国的普及发展贡献着最大的努力。坚持课程、教材、教学模式的研发升级、坚持搭建管理会计交流平台、坚持培养“能考会用”的CMA人才及管理会计在企业的大幅应用。如今优财学子遍布全国各地，全国17000名CMA持证者中，8500名出自优财。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui3/img23.jpg")
      },
      {
        "type":"txt",
        "src":"截至目前，优财（中国）CMA培训中心已成功举办31届中国管理会计实践论坛暨颁证典礼，管理会计实战课、沙龙、走进企业等活动数百场，参与人数突破3万人，同时，线上直播课已经成功举办100余期，参与人数覆盖10万人次，众多财务人员从中受益！"

      },
      {
        "type":"txt",
        "src":"今年是IMA管理会计师协会成立100周年，也是CMA进入中国10周年，同时，优财作为CMA中国首家培训机构，今年也迎来了10周岁的生日。优财也必不负期望，与IMA协会携手在未来的10年、100年为国家和企业培养出更多优秀的管理会计人才！"
      },
      {
        "type":"txt",
        "src":"让我们一同，见证精彩！"
      }
    ]
  },
  {
    id: "canhui4",
    "src": require("@/assets/images/article/canhui4.jpg"),
    time:"2019-08-06",
    name: "喜讯｜en+企业管理平台荣获湖南省2018年度优秀服务产品奖",
    content:[
      {
        "type":"txt",
        "src":"近日，湖南省工业和信息化厅公布了2018年度湖南省优秀服务产品奖获奖名单。经过自报、推荐、专家评审、公示，此次遴选出了11家能为我省中小微企业提供大数据、云计算资源、云存储资源、云应用软件、云服务等以云端计算、互联网应用为载体和平台的优秀云服务产品，湖南英富莱网络技术有限公司建设运营的en+企业管理平台成功入选。"
      },
      {
        "type":"txt",
        "src":"在随后举办的湖南省第五届中小企业服务对接会上，湖南省工业和信息化厅领导为湖南英富莱网络技术有限公司颁发了荣誉证书及2018年度“中小企业优秀服务产品”授牌。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui4/img1.jpg")
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui4/img2.jpg")
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui4/img3.jpg")
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui4/img4.jpg")
      },
      {
        "type":"txt",
        "src":"本次对接会以“数字化转型，高质量发展”为主题，由湖南省工业与信息化厅主办，旨在为深入贯彻落实党的十九大精神和《中小企业促进法》，加强对中小微企业创新的支持，促进我省中小企业与省内外优质服务资源对接，推动中小微企业高质量发展。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui4/img5.jpg")
      },
      {
        "type":"txt",
        "src":"2018年度“中小企业优秀服务产品”荣誉的获得，表明en+数字化在为企业提供一体化管理平台，实现系统与数据一体化，为企业资源管理及控制提供解决方案，实现移动智能的管理体验等方面获得了广大中小企业的广泛肯定。"
      },
      {
        "type":"txt",
        "src":"荣誉来之不易，en+数字化将不负众望，继续发挥专长，依托移动互联网技术，为企业提供多维度管理平台服务，立足本土中小企业，在中小企业公共服务领域深耕细作，不断提升企业服务的专业性和针对性，为湖南的经济发展做出更大贡献。"
      },
      {
        "type":"txt",
        "src":"en+企业管理平台以数据价值最大化为目标，基于数据流动和数据驱动的解决方案，已有许多成功的案例："
      },
      {
        "type":"txt",
        "src":"蓝斯股份CRM数字化转型｜系列（一）｜直击CRM管理痛点，最大化企业数据价值"
      },
      {
        "type":"txt",
        "src":"企业数字化转型案例| 智联招聘回款自动化与绩效管理数字化解决方案"
      },
      {
        "type":"title",
        "src":"en+数字化 重新定义了企业数字化转型服务"
      },
      {
        "type":"txt",
        "src":"en+数字化站在数据角度而不是专业的角度来重新定义企业数字化转型框架。"
      },
      {
        "type":"txt",
        "src":"即基于数据的全生命周期来设计方案，是基于数据流动的横向贯通的方案。专业细分能力（比如，业务，财务，人力，税务，法务）被提炼为数据处理规则，而这些规则的配置能力赋能给了企业和专家，从而建立了方案专业化和产品工具化的松耦合关系，就能够适应企业千差万别的数字化转型需求，也能聚合专家和解决方案，成为生态；"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui4/img6.jpg")
      },
      {
        "type":"txt",
        "src":"en+数字化站在方案(Solutions)，场景(Scenarios)和工具(Software)的3S驱动的角度而不是纯工具角度来重新定义企业数字化转型的方式与过程。"
      },
      {
        "type":"txt",
        "src":"方案：是企业数字化转型的灵魂，解决企业横向服务的效率问题；"
      },
      {
        "type":"txt",
        "src":"场景：是企业数字化转型的重点，解决企业纵向服务的效率问题；"
      },
      {
        "type":"txt",
        "src":"工具：是方案与场景的载体，最终为企业数据价值最大化服务；"
      }
    ]
  },
  {
    id: "canhui5",
    "src": require("@/assets/images/article/canhui5.jpg"),
    time:"2019-05-13",
    name: "en+数字化将会聚集更多的数字化转型力量，推动中国企业的数字化转型",
    content:[
      {
        "type":"txt",
        "src":"在全球经济一体化的大背景下，国际竞争加剧，国际经济贸易环境瞬息万变，经济持续转型升级，企业管理模式转变，同时赋予了财务管理新思维、新使命、新考验、新要求及新目标。财务人想要在变幻莫测的大环境下立于不败之地，就必须顺势而为，重造价值。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui5/1.png")
      },
      {
        "type":"txt",
        "src":"2019年5月11日，由美国管理会计师协会（IMA）、优财（中国）CMA培训中心联合主办的2019中国管理会计实践论坛在北京京仪大酒店隆重举行。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui5/2.png")
      },
      {
        "type":"txt",
        "src":"论坛围绕“顺势而为 重造价值”的主题，聚焦中国优秀企业的最佳管理会计实践案例展开激烈的思想交锋与碰撞，以颠覆的事业和创新的思维，推动所在企业引领财务管理创新、探寻新商业模式、实现质量和效益统一的企业财务负责人。"
      },
      {
        "type":"txt",
        "src":"论坛围绕“顺势而为 重造价值”的主题，聚焦中国优秀企业的最佳管理会计实践案例展开激烈的思想交锋与碰撞，以颠覆的事业和创新的思维，推动所在企业引领财务管理创新、探寻新商业模式、实现质量和效益统一的企业财务负责人。"
      },
      {
        "type":"txt",
        "src":"本次论坛邀请到了优财（中国）CMA培训中心总经理张敏女士，IMA美国管理会计师协会总裁兼CEO Jeffrey C. Thomson先生，IMA美国管理会计师协会中国区首席代表李刚先生，清华大学教授、博士生导师于增彪先生，en+数字化创始人兼CEO、前百度移动游戏CFO曾亮先生，碧生源控股有限公司首席财务官王娟女士，大型集团电商资深财务产品专家巨本省先生，北京天睿嘉弘软件有限公司RPA技术总监顾博先生，北京无量威德科技有限公司财务运营总监李小刚先生，兵器装备集团财务部价值创造办公室负责人李慜劼先生，北京诺诚健华医药科技有限公司财务运营总监李强先生，世纪睿科控股有限公司执行董事兼首席运营梁荣辉先生，某大型央企投资管理部高级主管姚立先生在内的众多权威学者、行业领袖和管理会计领域的卓越践行者，700余名企业高级管理人员和财务负责人现场聆听了此次思想盛宴。"
      },
      {
        "type":"txt",
        "src":"与此同时，本次论坛还开放了现场同步直播，引来了70000+财务人的同步围观。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui5/3.png")
      },
      {
        "type":"txt",
        "src":"论坛开始后，首先由优财(中国)CMA培训中心总经理张敏女士、 IMA美国管理会计师协会中国区首席代表李刚先生致开幕词，之后由Jeffrey C. Thomson、于增彪、曾亮、王娟、巨本省、顾博、李小刚、李慜劼、李强、梁荣辉、姚立等嘉宾带来精彩的演讲以及圆桌论坛分享。"
      },
      {
        "type":"title",
        "src":"优财CMA培训中心总经理张敏女士致开幕词"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui5/4.png")
      },
      {
        "type":"txt",
        "src":"张总首先对到场的嘉宾、参会人员以及支持单位、媒体支持表示了感谢，并表示像今天这样大型会场的公益论坛十年间已经举办第30届，大中小型和线上线下为优财学员提供的增值服务已经超过五百场，就是想为大家搭建一个平台、生态圈来拓展我们优财学员的视野，强化他们的管理会计思维，加强大家的交流、实践沟通机会。"
      },
      {
        "type":"txt",
        "src":"接下来张敏女士回顾了优财与IMA的渊源。IMA百年，优财十年。早在十年前，优财董事会就坚信“会计的未来是管理会计，管理会计必定是会计的未来”，于是主动选择与IMA合作，成为国内首家CMA培训机构。十年来，优财始终专注于CMA培训，并取得辉煌的成果。未来，优财还会继续专注CMA培训，为中国培养更多能考会用的优质的管理会计人才。"
      },
      {
        "type":"title",
        "src":"IMA美国管理会计师协会中国区首席代表李刚先生致开幕词"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui5/5.png")
      },
      {
        "type":"txt",
        "src":"李刚先生首先肯定了CMA在中国突飞猛进的发展，接着，对中国企业面临的越来越大的风险以及中国经济面临的前所未有的竞争进行了描述。"
      },
      {
        "type":"txt",
        "src":"李刚先生认为，企业要想降低风险，中国经济要想在激烈竞争中立于不败之地，行之有效的方法就是顺势而为，完全契合了本次论坛的主题。要想做到顺势而为，就需要做到与时俱进。"
      },
      {
        "type":"txt",
        "src":"IMA作为全球管理会计领域的百年老店，已经为财务人、企业、行业做到与时俱进提供了帮助："
      },
      {
        "type":"txt",
        "src":"1、发布《管理会计能力素质框架》，为财会专业人士应对当下及未来挑战所应具备的多种技能和素质指明了方向，得到了业界的广泛认可。"
      },
      {
        "type":"txt",
        "src":"2、提供CPE线上线下的学习服务。"
      },
      {
        "type":"txt",
        "src":"3、借助全国十多个精英俱乐部，为CMA持证者、CMA考生提供学习、探讨、提升的平台。"
      },
      {
        "type":"txt",
        "src":"最后，李刚先生还说到，未来IMA会不断加大对中国市场的投入，加大对CMA中文考试的投入，更好的为中国考生服务。"
      },
      {
        "type":"title",
        "src":"IMA美国管理会计师协会总裁兼CEO Jeffrey C. Thomson先生 《商业环境中会计师的未来》"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui5/6.png")
      },
      {
        "type":"txt",
        "src":"Jeffrey C. Thomso先生首先感谢了于增彪教授以及各位财务专家、行业领袖的到来，并郑重感谢了优财十年来的亲密合作。"
      },
      {
        "type":"txt",
        "src":"接着，Jeffrey C. Thomso先生回顾了自己40多年的从业经验，并从宏观上详细解答了数字化时代里，数字技术对财务人的影响。面对数字技术，Jeffrey C. Thomso先生鼓励大家去与时俱进，提升自己的影响力，提供价值创造。数字化时代里另一个急需提高素质就是数字技术。为什么要提高数字技能？因为我们的财会报告已经部分被数字化了，要想摆脱这方面的局限，获得更高的洞察力，就必须要提高数学、统计、数字化方面的能力。"
      },
      {
        "type":"txt",
        "src":"然后，Jeffrey C. Thomso先生解答了财务人如何在瞬息万变的商业环境中，提升价值创造的方法，很重要一点就是："
      },
      {
        "type":"txt",
        "src":'"从传统的管家和经营者角色，变成公司的催化剂和战略家。管家和经营者的角色在企业早期是非常重要的，比如保护公司财产、内部控制，交税、公司合规等。但是要想引导企业变革，要想去应对激烈的竞争，就必须成为企业的催化剂和战略家。"'
      },
      {
        "type":"txt",
        "src":"最后，Jeffrey C. Thomso先生向大家详细解答了职业道德的问题。无论是推动公司业绩的发展，维护公司声誉、监管资金的使用，职业道德都是必须重视的。2019年3月，IMA正式推出了新版《管理会计能力素质框架》，其中职业道德和价值观就是基石。"
      },
      {
        "type":"title",
        "src":"清华大学教授、博士生导师于增彪先生《管理会计应用与创新》"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui5/7.png")
      },
      {
        "type":"txt",
        "src":"作为财政部管理会计咨询专家、中国管理会计界泰斗，于教授对管理会计有着独到的见解。本次论坛上，于教授首先回顾了优财的历史，因为他见证了优财第一次与IMA合作，第一次面授班开班，见证了优财十年的快速发展，并寄语优财未来能够培养出100万名CMA持证者。"
      },
      {
        "type":"txt",
        "src":"接着，于教授从何谓业财融合、“业财融合”对CFO的挑战、机遇等几个方面对管理会计的来龙去脉以及未来的发展趋势做了详细解答。"
      },
      {
        "type":"txt",
        "src":"于教授说到，“业财融合”是中国实务界的概念，是对西方管理会计的中国式表达，中国财会人员“听得懂，做得来”。而将美国管理会计内容装入中国“业财融合”的框架，形成中国特色的管理会计应用与创新体系，是最理想的状态；将“业财融合”与IMA的CMA认证以及CMA职业发展结合起来，将有助于中国的CMA在实务中解决问题，建功立业，成就自己。"
      },
      {
        "type":"title",
        "src":"en+数字化创始人兼CEO,前百度移动游戏CFO曾亮先生 《CMA数字化转型的必由之路》"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui5/8.png")
      },
      {
        "type":"txt",
        "src":"曾亮先生从信息化与数字化的区别、企业与CMA的发展路径、数字化转型方法论、蜕变四个方面开启了CMA数字化转型必由之路的主题演讲。他首先对比了信息化与数字化的区别，对企业与CMA的发展路径做了详细的说明，然后对数字化的方法论以及注意事项进行了详细的解答。"
      },
      {
        "type":"txt",
        "src":"财务人员想要实现职业生涯最终的蜕变，需要满足三个条件："
      },
      {
        "type":"txt",
        "src":'"其一、从厚到薄，再从薄到厚。也就是让复杂的事情简单化,简单的事情数字化,数字的事情流动化,流动的事情价值化,价值的事情多元化。 其二、数字资产的价值管理，也就是有数据的支撑。 其三、形成影响力。如何形成影响力呢？考下CMA，利用数据,因为CMA是CFO的摇篮,CFO是CEO的摇篮,数据是影响力的摇篮。"'
      },
      {
        "type":"txt",
        "src":"会中，优财(中国)CMA培训中心为到场的200余名优财毕业学员颁发了证书，以及IMA十年来首次颁发的荣誉徽章。来自北京瀚亚资产管理有限公司财务总监李延辉女士、温氏食品集团股份有限公司财务主任郭珍女士，作为优秀学员代表发表了精彩分享。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui5/9.png")
      },
      {
        "type":"title",
        "src":"碧生源控股有限公司首席财务官王娟女士《管理会计实践之碧生源案例分享》"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui5/10.png")
      },
      {
        "type":"txt",
        "src":"王娟女士首先展示了碧生源的快速发展，以及所取得的辉煌成就，然后通过亲身实际工作经验以及管理会计的职能，详细解析了管理会计在碧生源公司主要职能板块的实际应用，比如核算、税务、上市合规、投资、融资、财务分析、投资者关系等等。"
      },
      {
        "type":"txt",
        "src":"最后，王娟女士对现场财务人发出了邀请，好好学习CMA课程，努力考下CMA证书，碧生源欢迎你的加入。"
      },
      {
        "type":"title",
        "src":"大型集团电商资深财务产品专家巨本省先生 《管理会计赋能“小站长”成为“大老板”》"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui5/11.png")
      },
      {
        "type":"txt",
        "src":"巨本省先生用幽默风趣的语言，借助电商平台的真实案例，详细解答了物流成本价值流、全业务过程成本控制、成本系统遵循的管理逻辑、物流成本系统总体框架、标准成本定制、业务成本及毛利测算、成本核算基本框架、估时作业成本核算、利润贡献、基于BSC的BigBoss全景指标等内容，最终得出一个结论，管理会计也是一种生产力，利用好管理会计，完全可以赋能“小站长”成为“大老板”。"
      },
      {
        "type":"title",
        "src":"北京天睿嘉弘软件有限公司RPA技术总监顾博先生 《管理会计实践之机器人流程自动化案例分享》"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui5/12.png")
      },
      {
        "type":"txt",
        "src":"顾博先生用浅显易懂的语言，为所有在场的财务人展示了财务共享中心功能的强大，所有基础财务工作都可以由机器人来操作。财务机器人可以自己上传发票、打印发票，自动开具发票，而且费用报销的机器人只是财务共享中心的第一步。"
      },
      {
        "type":"txt",
        "src":"总之，财务共享中心是一种变革，不仅是组织流程的变化，还是组织架构的变化。要想不被淘汰，行之有效的方法就是向管理会计转型，然后利用机器人来为你服务。"
      },
      {
        "type":"title",
        "src":"北京无量威德科技有限公司财务运营总监李小刚先生 《管理会计实践之中小型企业业财融合案例分享》"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui5/13.png")
      },
      {
        "type":"txt",
        "src":"李小刚先生用幽默风趣的语言为大家详细解答了业务和财务之间的矛盾所在，即业务不懂财务痛点，财务不懂业务关心重点。从财务人的角度来说，有效的解决方法就是用通俗化（易懂）+可视化（图表）+动态化（预警）+体系化（层次）的方式，来做好业务预测规划、预算追踪、监控调整、流程改进、创造价值。一句话概括，就是使用管理会计语言，也就是业务能够听得懂的语言。"
      },
      {
        "type":"txt",
        "src":"对于财务人非常关注的如何开展全面预算的问题，李小刚先生也给出了明确的答案，那就是以终为始，即做全面预算的时候要想做到让老板业主确保能得到预期投资和回报，让管理团队确保公司战略得到全面落地切实执行，就需要各责任衷心分解目标，人人扛任务，人人有指标，层层签订责权利，并做好实时监控、定期检讨，确保奖罚分明，让人人都心安。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui5/14.png")
      },
      {
        "type":"txt",
        "src":"下午茶歇之后，大会进入到了干货满满的圆桌论坛分享环节，由张敏 优财（中国）CMA培训中心总经理张敏女士担当主持人，来自某大型央企投资管理部高级主管姚立先生、兵器装备集团财务部价值创造办公室负责人李慜劼先生、北京诺诚健华医药科技有限公司财务运营总监李强先生、世纪睿科控股有限公司执行董事兼首席运营官梁荣辉先生共4位嘉宾，围绕“价值创造型管理会计之全面提升企业效能”的主题进行高端对话。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui5/15.png")
      },
      {
        "type":"txt",
        "src":"首先，李慜劼先生介绍了自己在兵装集团的岗位，以及所在部门的职责所在，即总结兵装集团内部管理会计案例、搭建兵装集团的管理会计体系、承接财政部管理会计工具的应用。面对当下的裁员潮，李慜劼给大家的建议是投资学习，投资机器人做不了的事情，投资能为财务决策者提供帮助的事情，目前最好的选择就是向管理会计转型，考下CMA，做到能考会用。"
      },
      {
        "type":"txt",
        "src":"此外，财务人还可以利用管理会计的工具，来解决当前面对的问题，比如波士顿矩阵、全面预算、全价值链管理，来为企业创造价值。能为企业创造更大价值的岗位，自然会被保留。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui5/16.png")
      },
      {
        "type":"txt",
        "src":"姚立先生给大家打了一个强心针，裁员每个年代都有，只是现代被更快的传播了。当然，大家也不能大意。在这个阶段："
      },
      {
        "type":"txt",
        "src":"首先要做好自我的提升，比如向管理会计转型； 其次要用心做好本质工作，比如出错率、完成率； 然后转换视角，多了解别的岗位，比如销售、业务部门，就可以从一个财务人的视角上升到更高的角度，这个时候你的核心竞争力不自觉间就提高了。"
      },
      {
        "type":"txt",
        "src":"如果能多站在公司高管的角度想问题，做好预算，这样的财务人就能为企业创造出价值了。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui5/17.png")
      },
      {
        "type":"txt",
        "src":"关于裁员危机，李强先生也给出了相同的答案，那就是学习。他认为财务是一个投入成本最低，收获最高的岗位。大家要想从跟不上解决这个问题，行之有效的方法就是向管理会计转型。至于如何为企业创造价值，就要使用业财融合，深入了解各个环节，这些事情都是财务人需要做到的。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui5/18.png")
      },
      {
        "type":"txt",
        "src":"梁荣辉先生对于企业裁员，他给的答案更明确，就是直接考CMA，因为CMA能为管理者做决策提供强有力的支撑，也能让自己更有竞争力。"
      },
      {
        "type":"txt",
        "src":"随着经济的全球化和一体化进程的加剧，国际间的竞争也在增长，企业对财务人员也提出更高的要求，越来越多财务精英、业务高管纷纷选择CMA来提升自己。优财作为CMA培训专家，凭借自身雄厚的实力，培养了无数的管理会计优秀人才，还搭建起了优质的CMA生态圈和管理会计交流平台，引领整个行业的发展和创新。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui5/s-1.png")
      },
      {
        "type":"txt",
        "src":"优财每年都会举办多次大型管理会计实践论坛，集结官产学研媒的意见领袖、企业领军人物以及管理会计领域的卓越践行者来解决大家的实际问题，推动管理会计在企业的广泛应用，也让每个参会者都拥有一双洞察世界的不一样的眼光。"
      },
      {
        "type":"txt",
        "src":"截至目前，优财CMA已举办线上线下管理会计公益讲座、论坛、沙龙数百场，参与人次逾50万人。未来，优财还会继续专注于CMA培训行业，继续推出更多、更优的线上线下火爆，帮助更多的财务人员成为财务变革时代的引领者和中坚力量！"
      }]
  },
  {
    id: "canhui6",
    "src": require("@/assets/images/article/canhui6.jpg"),
    time:"2019-03-25",
    name: "en+数字化助力管理会计产业化发展",
    content:[
      {
        "type":"txt",
        "src":"2019年3月24日，中国国际管理会计产业发展大会暨MACC2019年度战略发布会在北京国际会议中心隆重召开。本届大会以“解码数字时代，赢在管理会计”为主题，在2018年度中外管理会计行业研讨成果的基础上，继续结合国内外经济形势与当前管理会计的最佳实践，以国际化为向导、以产业化发展为目标，探讨中国管理会计产业的创新模式，为实现国家富强与管理会计产业的健康发展出谋划策、贡献力量。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui6/1.jpg")
      },
      {
        "type":"txt",
        "src":"本届大会得到了教育部、工信部、中国商业会计学会、中国国教师发展基金会、中国机电工业审计学会、中国机电工业价格协会、中金鹰和平发展基金会、北京工商大学、对外经济贸易大学、阿里巴巴钉钉、金蝶集团、用友畅捷通、中兴新云、京东方科技、高顿财经、中博教育、西安信达教育、河北航天、en+数字化、选哲科技、晖时共享、爱米财汇、财合税、易后台等近四百家国内外行业龙头企业及各地方政府、科技园区的大力支持，来自不同领域的近千名商业领袖、行业黑马、实战精英、知名专家集聚一堂，多角度、多层次探讨管理会计的价值所在，共商管理会计产业化新未来。中央电视台、北京电视台、网易财经、中国日报网、经济观察报、光明网、中华网等近百家主流媒体、新媒体、网络平台关注报道，现场在线直播受众人数近四万，此次会议中全球首次倡导的管理会计产业化发展已成为当下行业关注的热点。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui6/2.jpg")
      },
      {
        "type":"title",
        "src":"开拓管理会计新方向 挖掘行业发展新趋势"
      },
      {
        "type":"txt",
        "src":"从2013年起，财政部连续六年力推管理会计，管理会计从国家顶层设计走向地方携手推进，从理论宏观指导走向引导落地应用的阶段。管理会计已成为企业转型升级，提质增效及可持续发展的刚性需求。"
      },
      {
        "type":"txt",
        "src":"中国国际管理会计产业发展大会暨MACC2019年度战略发布会，通过国际先进管理会计经验和中国管理会计实践相结合，以及管理会计职业教育、管理会计信息技术以及管理会计咨询等领域的深度融合，逐渐形成管理会计产业化发展趋势，为中小企业健康发展注入新思路。"
      },
      {
        "type":"txt",
        "src":"大会现场，近百位政府代表和行业领袖通过生动专业的演讲、精彩纷呈的对话与讨论，分享前沿思想和战略思维，打破行业壁垒，颠覆传统认知，链接管理会计产业化发展的新生态。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui6/3.jpg")
      },
      {
        "type":"txt",
        "src":"从左到右，从上到下依次为：中国教师发展基金会理事长、原教育部部长助理杨周复，中国商业会计学会常务副秘书长黄国成，中国商业会计学会管理会计分会常务副会长兼秘书长马瑞阳、中金鹰和平发展基金会执行理事长、三亚市人民政府原副市长、中华人民共和国商务部原副司长李柏青，中国商业会计学会会长、北京工商大学副校长谢志华，中国商业会计学会管理会计分会会长白俊江"
      },
      {
        "type":"txt",
        "src":"中国教师发展基金会理事长、原教育部部长助理杨周复表示，传统财务会计已经逐步被现代科学技术手段所替代，管理会计专业人才已成为社会最紧缺的人才之一。因此，要大力开拓中国特色管理会计职业化发展之路，借鉴国际先进的管理会计理念，融合中国管理会计实践，建立一套与我国企事业单位实际情况相适应的系统化、规范化的中国特色管理会计理论体系。鼓励高校加强管理会计课程体系建设，全面推动企业深度参与、协同育人，鼓励企业和社会力量参与举办管理会计的职业教育，以便培养储备更多的管理会计人才。"
      },
      {
        "type":"txt",
        "src":"中国商业会计学会常务副秘书长黄国成表示，本次会议的主题是中国商业会计学会在管理会计领域的一次引领性探索，希望各界人士、企业及专家学者共同参与，为我国管理会计产业群策群力。"
      },
      {
        "type":"txt",
        "src":"中国商业会计学会管理会计分会常务副会长兼秘书长马瑞阳表示，管理会计可以为企业间的战略趋同、沟通顺同、管理协同提供帮助。在管理会计的发展过程中，不仅要关注人才培养，更要关注管理会计的人才应用，充分激活管理会计在经济环境中所发挥的重要作用，从而加速推进中国经济的发展。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui6/4.jpg")
      },
      {
        "type":"txt",
        "src":"工业和信息化部文化发展中心政策规划部主任李伟、阿里巴巴钉钉高级副总裁白惠源、中兴新云服务公司总裁陈虎、京东方科技集团股份有限公司执行副总裁姚项军、MACC金课将讲师团讲师刘星明、高顿财经MACC项目负责人胡丹露、中博教育MACC事业部总经理蒋玮、中国商业会计学会管理会计分会MAIC办公室主任姚秋帆"
      },
      {
        "type":"txt",
        "src":"中金鹰和平发展基金会执行理事长、三亚市人民政府原副市长、中华人民共和国商务部原副司长李柏青表示，数字经济时代，作为企业内部沟通的商业语言，管理会计已经开始参与到企业的规划和决策、控制和评价等一系列的业务流程、促进企业完善经营管理制度、帮助企业创造价值、提高经济效益、实现健康可持续的发展。呼吁社会各界力量，共同联手为建立人才培养、企业应用、财税服务、管理会计咨询、数字化管理五位一体的管理会计产业链贡献力量。"
      },
      {
        "type":"txt",
        "src":"中国商业会计学会会长、北京工商大学副校长谢志华教授为本届大会作主旨发言时表示， 由于企业生存发展的环境不同，决定了管理会计价值创造的逻辑起点不同、价值创造的方式不同，管理会计所要提供的决策信息也不相同。为此，需采取价值链、价值网络和价值星系对供给侧内部结构进行重建。此时，管理会计提供的决策信息需包括所有关联企业的信息以及市场个性化需求的信息。"
      },
      {
        "type":"txt",
        "src":"工业和信息化部文化发展中心，政策规划部主任李伟表示，面对经济转型和提质增效的艰巨任务，中国企业应将深化管理会计应用作为推进供给侧改革、提升管理创新的重要突破口，在管理领域积极引入先进的管理会计工具，深入推进业财融合，努力提高发展质量和效益。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui6/5.jpg")
      },
      {
        "type":"txt",
        "src":"财合税（北京）信息技术公司总经理阮志勇、易后台财税创始人兼CEO 姚宁、en+数字化创始人兼CEO、前百度移动游戏CFO曾亮、晖时共享董事长堵媛媛、钉钉华北区总监韩晓宇、广州市福思特科技有限公司董事长闫晓霞、用友畅捷通高级副总裁王学钧、著名财经主持人姚长盛"
      },
      {
        "type":"txt",
        "src":"对外经济贸易大学教授、中国商业会计学会管理会计分会常务副会长浦军表示，教育内容体系、人才培养体系、后续的就业体系是高校职业化教育的标配，而MACC认证课程体系既符合职业化的要求，又有成体系的培养模式，实战课程更贴近实际工作，能够有效推动管理会计职业化教育在高校生根发芽。"
      },
      {
        "type":"txt",
        "src":"阿里巴巴钉钉副总裁白惠源表示，未来已来，数字化组织、数字化经济已经是正在发生的未来。在未来的各个产业当中，一定是一个跨界协同、平台共建、生态共生的大网络跨界协同的时代。钉钉作为商业操作系统的基础设施，将从管理会计人才培养、管理会计咨询等多方面助力管理会计产业化发展。"
      },
      {
        "type":"txt",
        "src":"中兴新云服务公司总裁陈虎表示，智能化与数字化的变革，需要进一步拓展财务职能的深度与广度，完成由核算会计向管理会计转型。财务人员要拥有管理思维、运用最先进的管理会计工具，为企业管理层提供科学有效的决策依据。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui6/6.jpg")
      },
      {
        "type":"txt",
        "src":"图为大会两场圆桌论坛"
      },
      {
        "type":"txt",
        "src":"京东方科技集团股份有限公司执行副总裁姚项军表示，管理会计的运用一定要结合企业的商业模式，管理会计通过决策支持对企业商业模式的解读和落地助推企业转型。"
      },
      {
        "type":"txt",
        "src":"中国商业会计学会管理会计分会执行会长白俊江表示，管理会计专业人才培养、管理会计普及教育、管理会计咨询服务、管理会计信息化应用为管理会计产业化的发展提供了坚实基础，管理会计产业化发展将推动管理会计成为企业的常识、文化和语言，进而促进中国经济的发展。世界管理会计的春天在中国。"
      },
      {
        "type":"title",
        "src":"聚焦人才培养新模式 搭建行业交流多平台"
      },
      {
        "type":"txt",
        "src":"在中国经济转型和国际环境的巨变下，中国企业发展面临巨大的机遇与挑战，迫切需要既能融入国际主流，又能彰显中国特色的管理会计人才，让管理会计成为中国企业管理的语言、常识与文化，从而使管理会计在组织提质增效、构建核心竞争力中发挥重要作用。"
      },
      {
        "type":"txt",
        "src":"同时，本届大会隆重举行了MACC2019战略发布仪式、国际管理会计产业联盟成立仪式、国际管理会计研究院成立仪式、中国商业会计学会管理会计分会成立仪式、中国商业会计学会管理会计分会专家委员会聘任仪式、中国商业会计学会管理会计职业能力框架暨MAIC管理会计信息化认证项目发布仪式、MAIC项目实训基地授牌仪式、2019“金蝶杯”全国大学生管理会计案例分析大赛和2019＂福思特杯＂全国大学生管理会计实践创新精英挑战赛启动仪式。通过多种途径，全面推进管理会计人才队伍建设，努力营造良好的管理会计产业氛围，力争与业界同仁在2030年完成“中国百万管理会计人才”的宏伟目标，结合国际先进管理会计理论经验和我国管理会计实践，实现中国特色管理会计的理论与实践的全面发展。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui6/7.jpg")
      },
      {
        "type":"txt",
        "src":"在战略发布仪式环节，作为行业内备受瞩目的MACC认证项目正式发布全新发展战略，全新的品牌定位、知识框架、考试大纲，以及全新改版的官方教材也新装亮相。MACC管理会计课程体系，以国际先进管理会计知识体系为基础，与中国管理会计实践案例相结合，通过名师精讲精练、实战教学、MACC金课等实战+案例结合的教学方式，帮助学员快速了解国际化与本土化相融合的管理会计知识内容，不仅帮助学员掌握管理会计知识内涵，更是培养对管理会计实践做到活学活用的紧缺人才。"
      },
      {
        "type":"txt",
        "src":"中国商业会计学会在本次大会上正式成立了管理会计分会。中国商业会计学会(CCAI)经民政部批准于1987年成立，是由商业财会领域从事研究与实务的有关单位和人员自愿结成的全国性、学术性、非营利性国家一级社团组织。作为中国商业会计学会的直属分支机构，管理会计分会是由国内管理会计研究学者、管理会计实务界专业人士及企业组成，为推动和促进我国管理会计体系建设、管理会计人才培养工作、国际管理会计交流合作及管理会计领域“产、学、研、用”提供服务的公益性、学术性、非营利性社会团体，聘任厦门航空公司计划财务部总经理冯雁凌，财政部管理会计咨询专家、山水文园投资集团高级副总裁兼CFO付华，中兴新云服务公司总裁陈虎，对外经贸大学、中国商业会计学会管理会计分会副会长浦军教授，北京工商大学王斌教授为中国商业会计学会管理会计分会专家。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui6/8.jpg")
      },
      {
        "type":"txt",
        "src":"与此同时，中国商业会计学会管理会计分会在现场发布了数字化管理会计职业能力框架及MAIC管理会计信息化认证项目。数字化管理会计职业能力框架（2019）体系认为，数据时代的管理会计作为企业内部通用商业语言，以价值创造为最终目的，围绕价值计量、价值分析、价值创造、价值管理这一逻辑主线，需要从业人员具备数据与信息、报告与预算、业务与运行、战略与控制四大业务能力，同时具备相应的领导与沟通能力。五大能力各自对不同层级从业人员的要求不尽相同，从而形成四大职级体系。以此框架为基础，推出了财务人员转型与上岗必备的管理会计信息化认证项目（MAIC），旨在帮助学员彻底摆脱学习管理会计后无法在职场工作中实际应用的困境，并与山东财经大学、内蒙古财经大学、内蒙古农业大学、广东技术师范大学、许昌学院商学院、甘肃财贸职业技术学院、江苏经贸职业技术学院共建MAIC项目实训基地，打造职业教育“1+X”模式，创建中国管理会计的实训教学场景，为学生搭建职业发展平台。"
      },
      {
        "type":"txt",
        "src":"在与会嘉宾共同见证下，“国际管理会计产业联盟”正式成立。联盟将联合政、产、学、研、商各界力量，搭建管理会计创新与应用的交流平台，筛选推荐优质资源项目，赋能管理会计产业发展，推进管理会计高等教育体系、信息科技及企业应用的全方位融合和产学研协同创新，并有效整合及高效使用中外管理会计经验和资源帮助企业提质升级，适应和引领经济发展新常态，提升企业国际竞争力，从而进一步推动我国经济的快速发展。"
      },
      {
        "type":"title",
        "src":"凝聚行业智慧 树立行业标杆"
      },
      {
        "type":"txt",
        "src":"我国对管理会计的研究和应用起步较晚于西方国家，2013年财政部推动管理会计发展以来，在短短的几年中，管理会计从指引体系、理论体系和实践上已取得了可喜的成绩。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui6/9.jpg")
      },
      {
        "type":"txt",
        "src":"“大鹏之动，非一羽之轻也；骐骥之速，非一足之力也”。管理会计发展必须依靠相关各方紧密联动、加强配合、集中智慧、密切合作。在本届大会颁奖盛典上，多项关于MACC年度奖项盛大揭晓。近百家企业和个人分别荣获MACC年度最佳教学支持奖、MACC年度十佳教育机构奖、MACC年度杰出贡献奖、MACC年度最具潜力奖、MACC年度创新实践奖、2019年度财务科技创新奖、MACC年度开拓创新奖、MACC年度传播大使、MACC年度十大教育领军人物等奖项，以表彰过去一年里，在MACC认证项目发展过程中各合作伙伴和领袖所取得的优异成绩。"
      },
      {
        "type":"txt",
        "src":"“解码数字时代，赢在管理会计”， 中国国际管理会计产业发展大会暨MACC2019年度战略发布会是进一步贯彻落实国务院关于职业教育、财政部关于大力发展管理会计的要求开展的一次管理会计标杆盛会。推动管理会计产业化发展、建设管理会计人才队伍、助推中国经济转型发展是我们义不容辞的使命和责任。新的一年，让我们共同携手，为实现国家富强和管理会计产业的健康发展出谋划策、竭诚尽智！"
      }
    ]
  },
  {
    id: "canhui7",
    "src": require("@/assets/images/article/canhui7.jpg"),
    time:"2018-11-20",
    name: "en+数字化为管理会计落地方案提供方法论和工具",
    content:[
      {
        "type":"txt",
        "src":"导读：2018年11月17日，由优财（中国）CMA培训中心、美国管理会计师协会IMA 联合主办的2018中国管理会计实践论坛今日在北京京仪大酒店隆重举行。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui7/img1.jpg")
      },
      {
        "type":"txt",
        "src":"本届论坛云集了众多来自实务界的企业经营者、决策者、财务领袖及权威学者等重量级嘉宾，聚焦中国优秀企业的最佳管理会计实践案例展开激烈的思想交锋与碰撞，以全新的视野和创新的思维，探讨管理会计如何更好地实践落地，助推企业倍速发展，再一次将中国管理会计的整体水平推向了更高的高度！"
      },
      {
        "type":"txt",
        "src":"虽然外面天气寒冷，但会场内气氛热烈、座无虚席，来自众多企业的高级管理人员、财务精英，优财毕业的CMA学员以及各大媒体记者等共计500余人共同现场聆听了此次思想盛宴。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui7/img2.jpg")
      },
      {
        "type":"txt",
        "src":"大会开始后，优财（中国）CMA培训中心总经理张敏女士率先致开幕辞，她首先对到场的嘉宾和参会人员表示感谢，并表示像今天这样大型的五百人会场的论坛十年间已经举办了27届，大中小型和线上线下为优财学员提供的增值服务已经超过五百场，希望为大家搭建一个平台、生态圈来拓展我们优财学员的视野，强化他们的管理会计思维，加强大家的交流、实践沟通机会，这是一件特别有使命感的事情，并且会持续坚持下去。"
      },
      {
        "type":"txt",
        "src":"接下来张敏女士回顾了优财与IMA的渊源，十年来专注CMA培训事业的初心、愿景和优财的定位，以及围绕初心和定位所进行的努力和取得的成果。值得一提的是，优财成立了管理会计研究院，希望进一步提升优财CMA专家的培训专业度，锁定优财的优势，专注案例式教学，旨向培养有用的CMA，培养对职业发展有帮助的CMA人才，帮助学员跻身财政部号召的三万分之一管理会计师！"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui7/img3.jpg")
      },
      {
        "type":"txt",
        "src":"优财（中国）CMA培训中心总经理张敏女士"
      },
      {
        "type":"txt",
        "src":"接下来IMA市场总监Echo继续致开幕词，她表示，“IMA协会进入中国已经十一年了，从十多年的发展历程中，我们非常欣喜地看到管理会计的概念从最初并不为大家所认知，到现在管理会计成为大势所趋，如今管理会计已经上升到国家战略层面，管理会计师未来也是我们的核心人才。”Echo提到，十多年的发展离不开合作伙伴的坚定支持，优财就是我们最为亲密的合作伙伴之一，同时，十多年发展过程中还有效构建了一个管理会计的生态系统，包括管理会计发展体系和传播体系，源源不断地为中国管理会计事业发展注入我们的力量，CMA项目借助这个平台也得到了非常扎实、快速的发展，它的价值也不断被大家所认可。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui7/img4.jpg")
      },
      {
        "type":"txt",
        "src":"IMA市场总监Echo"
      },
      {
        "type":"txt",
        "src":"北京平恒投资管理有限公司副总裁、合伙人王国柱先生发表了《解码管理会计的价值创造之道》为主题的演讲，深入剖析了企业价值的本源是什么、企业价值的本源从哪里来和企业价值创造的管理会计之道。他指出，企业价值创造的源泉是产品创新、管理创新、商业模式创新和业务创新；企业价值创造的逻辑在于赚钱、花钱、分钱和筹钱的逻辑；企业价值创造源泉上要解决因果之道、整合之道、共赢之道。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui7/img5.jpg")
      },
      {
        "type":"txt",
        "src":"北京平恒投资管理有限公司副总裁、合伙人王国柱先生"
      },
      {
        "type":"txt",
        "src":"en+数字化创始人兼CEO、前百度移动游戏CFO曾亮先生以“管理会计方案落地必备技能”为题，分别阐述了管理会计方案的特征、方案落地的必要条件和方案落地的必备技能。"
      },
      {
        "type":"txt",
        "src":"曾亮先生表示，“经济下行形势下，企业不再向外看而是向内看，要练好内功，将数据价值最大化，用数据驱动练好内功是第一”，“我们学了管理会计不是回去做管理会计，而是用思维方式推动管理，解决问题，首先你要了解业务，你要把财务的语言业务化、要把业务数据财务化，这要求你的翻译能力特别强，要把标准的语言翻译明白配合你执行，这非常重要。”最后，曾亮先生指出管理会计方案落地的必备技能，就是要具备结构化思维能力、数字化整合能力、项目管理能力以及风险洞察能力。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui7/img6.jpg")
      },
      {
        "type":"txt",
        "src":"en+数字化创始人兼CEO、前百度移动游戏CFO曾亮先生"
      },
      {
        "type":"txt",
        "src":"原澳大利亚上市公司财务总监/CMA会员陶俊光带来了《从会计起源看管理会计的发展》的精彩演讲。他认为，管理会计不是会计行为，而是管理行为，那么管理会计的发展和管理理论的发展是相辅相成的。管理会计是一个创造价值的信息系统，财务会计是反映价值的信息系统，是事后；要创造价值强调的是事中和事前管理，事前要算，事中要控制经营活动符合公司的战略。最后，陶总又简明阐述了管理会计的三个系统：第一是决策支持系统，第二是员工行为调节系统，第三是管理控制系统。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui7/img7.jpg")
      },
      {
        "type":"txt",
        "src":"原澳大利亚上市公司财务总监/CMA会员陶俊光先生"
      },
      {
        "type":"txt",
        "src":"随后，优财（中国）CMA培训中心还为现场200余名在上个考季中新鲜出炉的CMA亲手颁发了证书，将论坛推向了高潮。获证学员分批次上台接受了这张沉甸甸的财经领域黄金认证，激动的心情溢于言表，现场500余名与会人员共同见证了优财学员喜获证书的荣耀时刻。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui7/img8.jpg")
      },
      {
        "type":"txt",
        "src":"其中东方园林环保集团营销中心总经理王昊先生和学员夫妻韩鹏飞、曹艳红，作为优秀学员代表，分别为与会人员带来了精彩分享。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui7/img9.jpg")
      },
      {
        "type":"txt",
        "src":"午饭交流过后，IMA协会亚太区总监白俊江先生就《管理会计与领导力和组织建设》的话题，从不同的角度来诠释管理会计，包括管理会计的发展以及应用过程中个人需要具备和培养哪些能力。"
      },
      {
        "type":"txt",
        "src":"白俊江先生表示，现在企业面临非常复杂的企业经营环境，要实现企业的生存，必须要进行共同学习，基础就是要有一种共同的语言符号，而管理会计就是在价值发现、价值创造、价值计量、价值管理角度的语言和体系，因此他认为管理会计应该成为企业管理的交流语言以及组织建设的基础。"
      },
      {
        "type":"txt",
        "src":"另外，白俊江先生希望推动中国管理会计服务产业的发展，建立管理会计的服务产业，让更多的管理会计专家可以成为外来念经和尚，让企业为管理会计的价值产生充分的认同。接下来，白总从激励、领导力本身、团队建设、激励这四个方面阐述了如何利用管理会计的工具、方法和语言来提高领导力。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui7/img10.jpg")
      },
      {
        "type":"txt",
        "src":"IMA协会亚太区总监白俊江先生"
      },
      {
        "type":"txt",
        "src":"IMA协会全球理事、优财管理会计研究院院长杨晔先生，带来了《企业发展视角下的管理会计职业道德思考》的精彩分享，杨晔先生是国内首席案例式教学专家，他的演讲围绕职业道德和企业价值创造的密切关系，穿插大量真实的商业案例，对职业道德和价值观提升商业运营能力、促进管理思维不断创新等角度进行了深入浅出的讲解，与会人员听得认真、专注，意犹未尽。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui7/img11.jpg")
      },
      {
        "type":"txt",
        "src":"IMA协会全球理事、优财管理会计研究院院长杨晔先生"
      },
      {
        "type":"txt",
        "src":"下午茶歇之后，大会进行到重磅的圆桌论坛干货分享环节，由兵器装备集团财务部价值创造办公室负责人李慜劼先生担当主持人，来自IMA协会全球理事、优财管理会计研究院院长杨晔先生，华科诚信CEO、前用友erp专家许艳涛先生，眉州东坡集团财务中心总经理党莉芳女士，原华为驻香港某全球业务部CFO周礼军先生以及中国电子科技集团财务处肖潇雨女士共5位嘉宾，共同围绕“中小企业现金流管理及如何降本增效，提升企业价值”的主题进行了热烈的讨论。话题是经过优财主办方精心设计的，实用、接地气，也是众多财务工作者所关心的话题，为工作在各个财务岗位上的与会人员带来满满的干货。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui7/img12.jpg")
      },
      {
        "type":"txt",
        "src":"首先，肖潇雨处长从降本增效的核心目的、很多企业开展降本增效工作的欠缺以及五项解决措施来谈了自己对于中小企业降本增效这个问题的体会；关于企业如何降本，党莉芳女士结合自己的餐饮集团在这方面所做的工作做了分享，她说，“希望通过信息技术的提升不断提高我们的管理水平，我们明年公司整体战略目标就是极致体验、极致效率以及改进整个流程，包括流程的优化、IT技术的提升”。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui7/img13.jpg")
      },
      {
        "type":"txt",
        "src":"中国电子科技集团财务处肖潇雨女士"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui7/img14.jpg")
      },
      {
        "type":"txt",
        "src":"眉州东坡集团财务中心总经理党莉芳女士"
      },
      {
        "type":"txt",
        "src":"面对我国中小企业融资难、融资贵的困境，中小企业如何另辟蹊径寻找其他的融资渠道呢？许艳涛先生给大家带来了一些有效的建议。他指出，财务人员要更加的贴近业务，去业务前端关注业务的形式，前端的信息属于非财务信息，而非财务信息可能是领先于传统财务信息的，因为财务数据是历史成本，我们要更加贴近业务、更加了解业务，做到业财融合。 "
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui7/img15.jpg")
      },
      {
        "type":"txt",
        "src":"华科诚信CEO、前用友erp专家许艳涛先生"
      },
      {
        "type":"txt",
        "src":"关于企业内部的现金流管理如何操作，周礼军先生结合自己在华为和中小企业的工作经历带来了一些思考。他说，融资困难和回款困难，双重压迫使中小企业面临现金流的困境，另外中小型企业尤其是民营科技型企业财务并不规范，账实严重不符是客观情况并且将会长期存在，现金流挑战真的很大，这是一个任重道远的事情。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui7/img16.jpg")
      },
      {
        "type":"txt",
        "src":"原华为驻香港某全球业务部CFO周礼军先生"
      },
      {
        "type":"txt",
        "src":"接下来，杨晔老师分享了中小企业应该更加注重投入产出和资源配置的观点。他表示，现在一些管理的理念，包括财务的理念可以给中小企业非常好支撑，像财务的指标、经营周期、现金周期基本指标对于像中小企业的经营者和管理者都应该密切关注，经营周期和现金周期预期差得远说明现金流有一些问题。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui7/img17.jpg")
      },
      {
        "type":"txt",
        "src":"IMA协会全球理事、优财管理会计研究院院长杨晔先生"
      },
      {
        "type":"txt",
        "src":"最后，踊跃的现场提问环节之后，主持人李慜劼对上述嘉宾的分享做总结发言，最后他提到，中小企业财务人员应对人工智能时代的挑战，要积极进行财务转型，国家大力推动管理会计，IMA、优财关注于管理会计的培训，会计的未来是管理会计，管理会计是会计的未来，而管理会计的未来已来！"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui7/img18.jpg")
      },
      {
        "type":"txt",
        "src":"兵器装备集团财务部价值创造办公室负责人李慜劼先生"
      },
      {
        "type":"txt",
        "src":"论坛闭幕后，意犹未尽的学员们迟迟不肯离去，围着嘉宾还在进行热烈的探讨。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui7/img19.jpg")
      },
      {
        "type":"txt",
        "src":"随着区块链、人工智能等新兴科技的发展，企业对财务人员也提出更高的要求，越来越多财务精英、业务高管以及致力于提升自己的职场之士，纷纷选择CMA，加入到管理会计滚滚大潮的前列。而优财作为CMA培训专家，除了凭借自身雄厚的实力，培养管理会计优秀人才外，还搭建起了优质的CMA生态圈和管理会计交流平台，以发挥引领行业变革创新的作用和价值。"
      },
      {
        "type":"txt",
        "src":"所以每年由优财主办的大型管理会计实践论坛，汇集官产学研媒的意见领袖、企业领军人物以及管理会计领域的卓越践行者，就是为了打造全新的管理会计体系，推动管理会计在企业的大幅应用，也让每个参会者拥有一双洞察世界的不一样的眼光！"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui7/img20.jpg")
      },
      {
        "type":"txt",
        "src":"为了更有效地推广CMA，优财还独家推出了案例式教学模式以及业内唯一公开出版的CMA教辅书，并不断加强科研和师资力量，让学员收获的不仅仅是证书，真正做到实践应用，实现自身价值提升。如今，优财培养的CMA学员活跃在各行各业，肩负着财务管理的重任，成为企业价值创造的中坚力量。"
      },
      {
        "type":"txt",
        "src":"截至目前，优财CMA已举办线上线下管理会计公益讲座、论坛、沙龙几百场，参与人次逾10万人，众多在职财务人员从中受益。接下来，优财仍会不忘初心，继续专注于CMA培训事业，助力越来越多的财务人员成为财务变革时代的引领者和中坚力量！"
      }
    ]
  },
  {
    id: "canhui8",
    "src": require("@/assets/images/article/canhui8.jpg"),
    time:"2018-09-16",
    name: "en+数字化会推动管理会计产业化，生态化！",
    content:[
      {
        "type":"txt",
        "src":"台风来了！在对台风见怪不怪的东南沿海城市的人们来说，这次山竹的威力不容小觑。与台风一样席卷而来的，还有火爆的管理会计热潮。"
      },
      {
        "type":"txt",
        "src":"随着全球竞争的加速和企业经营管理模式的转变，企业对财务人员的定位和能力提出了更高的要求；同时，区块链、云计算、财务机器人等一系列新兴技术的发展使财会人员面临越来越严峻的挑战。"
      },
      {
        "type":"title",
        "src":"新形势下，财务人转型管理会计已成必然趋势！"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui8/stimg1.png")
      },
      {
        "type":"txt",
        "src":"2018年9月15日，由美国管理会计师协会(IMA)、CMA培训专家-优财(中国)CMA培训中心主办，CMA深圳精英俱乐部、en+数字化协办的《2018中国管理会计实践论坛暨优财华南区CMA颁证典礼》在深圳·阳光酒店隆重举行。"
      },
      {
        "type":"txt",
        "src":"论坛围绕着“新时代、新科技、新财务”三个方面，聚焦中国优秀企业的最佳管理会计实践案例，剖析在新时代下财务人如何乘上新科技的航帆成为新一代的财务管理者，为企业创造价值。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui8/stimg2.png")
      },
      {
        "type":"txt",
        "src":"本次大会邀请到了包括优财（中国）培训中心总经理张敏女士，IMA协会亚太区总监白俊江先生，IMA协会全球理事、优财管理会计研究院院长杨晔先生，深圳前海亚讯电子商务有限公司财务总监李斌先生，元年科技解决方案总监唐宏钧先生，en+数字化创始人兼CEO曾亮先生，CMA会员、黑弧奥美财务总监、前华为国家财务总监简进波先生，CMA会员、神州鹰软件科技任财务总监吴宁先生，CMA会员、微软（中国）有限公司高级财务经理黄洁婷女士，金蝶集团首席财务专家翁晓文先生等重量级嘉宾参加。"
      },
      {
        "type":"txt",
        "src":"另外还有来自国内众多一流机构和企业的财务总监、高级财务经理、财务部长等高管人员及优财CMA毕业学员等300多人一起参与了这次盛大的年度行业论坛。会场内座无虚席，火爆异常！与此同时，还有6000多人次通过网络全程参与了现场直播。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui8/stimg3.png")
      },
      {
        "type":"txt",
        "src":"大会开始后，首先由优财（中国）培训中心总经理张敏女士、IMA协会全球理事杨晔先生和IMA协会亚太区总监白俊江先生分别致开幕词，之后由李斌、唐宏均、曾亮、简进波、杨晔、吴宁、黄洁婷和翁晓文共八位嘉宾带来了精彩的演讲。"
      },
      {
        "type":"title",
        "src":"优财CMA培训中心总经理张敏女士致开幕词"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui8/stimg4.png")
      },
      {
        "type":"txt",
        "src":"张总首先代表优财（中国）CMA培训中心向百忙之中抽身前来参加论坛的各位嘉宾表示了感谢，同时对即将新鲜出炉的CMA表示了祝贺。"
      },
      {
        "type":"txt",
        "src":"对于优财在每次考期结束后都会在北京、深圳、南京、杭州、苏州等考点城市举办CMA的颁证典礼，张总表示，一方面是祝贺这些获得证书的学员，另一方面旨在搭建一个管理会计沟通交流、协作的平台，强化财务界管理会计的意识，传播管理会计的理念、知识和实践。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui8/stimg5.png")
      },
      {
        "type":"txt",
        "src":"作为国内首家IMA授权的CMA培训机构，张总分享了优财和CMA、IMA的渊源以及专注CMA培训事业十年来所作出的努力和取得的成就，并表示优财会一直秉持“让每个会计成为管理会计”的愿景和信念，在CMA培训的路上继续坚持，成为这个领域真正的CMA培训专家，培养最优良的，最能把理论和实际起合出来的管理会计人才。"
      },
      {
        "type":"title",
        "src":"IMA协会全球理事杨晔先生致开幕词"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui8/stimg6.png")
      },
      {
        "type":"txt",
        "src":"杨晔先生以格力、双汇等企业按照管理会计思路由弱变强、市场份额大幅增长等一些商业现象举例，呼吁大家在学习和思考的过程中，多用管理会计的一些思维、技能和工具武装自己和企业。"
      },
      {
        "type":"txt",
        "src":"他还表示现在企业面临很大的挑战，新科技的发展已经改变了很多企业的组织结构和商业模式，而管理会计能引领企业发展和变革，希望大家拥抱新的科技，不断学习管理会计，用管理会计和CMA为企业创造价值，也能为个人带来更大的价值。"
      },
      {
        "type":"title",
        "src":"IMA协会亚太区总监白俊江先生致开幕词"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui8/stimg7.png")
      },
      {
        "type":"txt",
        "src":"白总首先肯定了CMA在中国的突飞猛进的发展，接下来以《管理会计的新蓝海》为主题分享了一些对于管理会计的体会和思考。"
      },
      {
        "type":"txt",
        "src":"白总表示，不同规模的企业对于管理会计的理解存在很大差异，很多学员学完CMA后不知道怎么去发展，怎样去体现自己的价值，所以他认为要从另外一个角度来提升管理会计的价值，提升个人和企业价值，需要经过三个阶段："
      },
      {
        "type":"txt",
        "src":"第一，需要让更多的人从非专业化的角度了解管理会计，去专业化。"
      },
      {
        "type":"txt",
        "src":"第二，发展管理会计咨询体系，实现管理会计产业化。"
      },
      {
        "type":"txt",
        "src":"第三，提倡会计人员学了管理会计，成为管理者，而不是再去当管理会计。"
      },
      {
        "type":"title",
        "src":"深圳前海亚讯电子商务有限公司财务总监李斌先生《新经济形势下财务转型的思考》"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui8/stimg8.png")
      },
      {
        "type":"txt",
        "src":"李斌先生从财务从业者的职业思考，新经济技术对财务职业冲击，以及财务人职业发展等方面，分享了自己对财务人员转型的思考和理解。"
      },
      {
        "type":"txt",
        "src":"李总诙谐、幽默、风趣的分享引发台下阵阵掌声，以通俗易懂的方式引发大家对财务工作及转型管理会计的深思。"
      },
      {
        "type":"title",
        "src":"元年科技解决方案总监 唐宏钧先生《财务转型与管理会计信息化-数字化》"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui8/stimg9.png")
      },
      {
        "type":"txt",
        "src":"唐总通过阿尔法狗、图像识别、移动支付等人工智能技术对人们生活的影响出发，引发深思，希望大家去了解这些技术会给我们整个的财务管理、管理会计带来什么样的变化。"
      },
      {
        "type":"txt",
        "src":"接下来唐总以元年的角度，详细阐释了数字化的三层含义："
      },
      {
        "type":"txt",
        "src":"底层是基础核算层;"
      },
      {
        "type":"txt",
        "src":"第二层是业务管控层；"
      },
      {
        "type":"txt",
        "src":"第三层是一整套的决策支持体系。"
      },
      {
        "type":"txt",
        "src":"en+数字化创始人兼CEO 曾亮先生《en+数字化助推企业数字化转型》"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui8/stimg10.png")
      },
      {
        "type":"txt",
        "src":"曾亮先生就当前财务工作面临的挑战进行了深入剖析，从数字化发展趋势、什么是新动能、如何获取新动能等方面，详细解读了财务助推企业转换新动能的关键在于业财融合、数据驱动。"
      },
      {
        "type":"txt",
        "src":"最后曾亮先生总结说“离数字全貌最近、能够做到业财融合的人就是管理会计，懂业务，又懂财务，数据在我们身边，只需要一个适合你的工具和方案落地就行了。”"
      },
      {
        "type":"txt",
        "src":"黑弧奥美财务总监、前华为国家财务总监简进波先生《管理会计在Fortune500的实践项目管理》"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui8/stimg11.png")
      },
      {
        "type":"txt",
        "src":"简总主要以华为的一些工作作为体验，介绍了华为项目的概算、预算、核算、结算。"
      },
      {
        "type":"txt",
        "src":"简总表示，“现在的企业当中，以前的功能性组织不再适合现在迅变的商业环境”，“而项目型组织越来越适应市场需求”。现在大部分集团性企业都用了项目性组织的方式去管理，财务参与其中做概预核决，分几方面讲解了项目财务在项目型组织中的重要作用。"
      },
      {
        "type":"txt",
        "src":"总结来说，做项目关键的成功要素，第一，明确项目范围。第二，识别风险。第三，跟人打交道。第四，有归档。"
      },
      {
        "type":"title",
        "src":"IMA协会全球理事、优财管理会计研究院院长 杨晔先生《管理会计提升企业决策者的素养》"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui8/stimg12.png")
      },
      {
        "type":"txt",
        "src":"杨晔老师首先介绍了三位著名的经营者，分别是华为CEO任正非、日本丰田前副社长大野耐一和711的前社长铃木敏文，他们的共性就是在过去的运营历史中，都用了很多管理会计的经验。"
      },
      {
        "type":"txt",
        "src":"接下来运用大量的企业经营案例如滴滴、好想你枣、康师傅矿泉水、顺丰快递等案例，剖析了商业现象下的管理会计应用，解读了现代经营决策者所需要具备的四大素养，分别是前瞻力、决策力、控制力和评价性。"
      },
      {
        "type":"title",
        "src":"神州鹰软件科技财务总监 吴宁先生《业财融合需要什么样的管理会计》"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui8/stimg13.png")
      },
      {
        "type":"txt",
        "src":"吴总通过分析传统管理会计出现的问题，管理会计者的几个困惑，指出只拿到CMA证书并不能成为真正的价值创造者，要从管理会计角色到有管理会计思维或管理运营者的思路，就需要“走出去”，需要“渗透性”。"
      },
      {
        "type":"txt",
        "src":"然后通过七个实际案例循序渐进的讲解了企业到底需要什么样的管理会计。"
      },
      {
        "type":"title",
        "src":"微软（中国）有限公司高级财务经理 黄洁婷女士《智能化工具的应用》"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui8/stimg14.png")
      },
      {
        "type":"txt",
        "src":"黄洁婷女士主要为大家介绍了一系列微软的实用性工具，能够帮助日常工作提高效率。"
      },
      {
        "type":"txt",
        "src":"另外，通过介绍微软现在使用的管理所有租赁的工具阐明一个观点：随着环境的变化，工具也变得越来越复杂，使用中也会遇到很多问题，但每一个工具的发展从最开始到变成好用，都要经过不断试错的过程。"
      },
      {
        "type":"txt",
        "src":"金蝶集团首席财务专家 翁晓文先生《基于管理会计视角的企业风险管理》"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui8/stimg15.png")
      },
      {
        "type":"txt",
        "src":"翁晓文先生分三个部分开展了今天的分享："
      },
      {
        "type":"txt",
        "src":"第一部分，管理会计是企业风险管理的引领者；"
      },
      {
        "type":"txt",
        "src":"第二部分，管理会计职能贯穿风险管理全过程，通过大量案例讲解管理会计和企业实际是怎样融合的；"
      },
      {
        "type":"txt",
        "src":"第三部分，管理会计实践推动风险管理进步。幽默又深入浅出的讲解让参会人员听的聚精会神，满满的干货让人意犹未尽。"
      },
      {
        "type":"txt",
        "src":"会中，IMA协会亚太区总监白俊江先生、优财（中国）总经理张敏女士、IMA协会全球理事杨晔先生分别作为颁奖嘉宾，还为到场的优财华南区近200名CMA毕业学员颁发了证书。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui8/stimg16.png")
      },
      {
        "type":"txt",
        "src":"其中，来自深圳金溢科技股份有限公司的财务总监聂磊和来自中信资产管理有限公司华南分公司的财务总监林沛榕先生，分别作为优秀学员代表进行了发言。"
      },
      {
        "type":"txt",
        "src":"“通过CMA的学习拿到了证书，但我觉得这不是终点，每年还有30个CPE要学习，同时CMA协会每年也会发布素质和管理能力框架，我觉得这是一种与时俱进，我想这也正是我们CMA的生命力所在。”"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui8/stimg17.png")
      },
      {
        "type":"title",
        "src":"聂磊"
      },
      {
        "type":"txt",
        "src":"“我觉得管理会计主要是一种思维，主要是如何站在企业的角度思考企业的发展，不仅仅是原来简单的财务管理，需要各方面的思考”。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui8/stimg18.png")
      },
      {
        "type":"title",
        "src":"林沛榕"
      },
      {
        "type":"txt",
        "src":"当沉甸甸的证书颁发到学员手中时，意味着又有一批精于财务、善于管理和决策的管理会计人才将在今后的职业发展中，更好的实现个人价值，同时也为企业创造更多的价值。"
      },
      {
        "type":"txt",
        "src":"颁证典礼是CMA培训专家优财独有的线下活动，每年都会为学员举办至少三次的颁证典礼活动，以嘉奖学员取得的好成绩和对他们的祝贺。        "
      },
      {
        "type":"txt",
        "src":"本次颁证典礼已是在深圳举办的第四届、全国范围内的第26届，覆盖学员过万人次。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui8/stimg19.png")
      },
      {
        "type":"txt",
        "src":"随着财务信息化、智能化技术的发展，企业管理模式的转型升级也随之加速推进，精财务、懂业务、会管理、能决策的管理会计作为企业价值创造者越来越受到企业的青睐。"
      },
      {
        "type":"txt",
        "src":"毫无疑问，管理会计是会计的未来，会计的未来是管理会计！而CMA作为全球认可的权威管理会计师认证，已经成为新一代财务管理者的最佳选择！"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui8/stimg20.png")
      },
      {
        "type":"txt",
        "src":"优财自2009年成为国内首家IMA授权的CMA培训机构以来，为IMA促进CMA项目在中国的普及和推广，搭建起了有利的平台，并且连续十年始终专注CMA培训，凭借自身的深厚实力，为国家和企业培养了大量的管理会计人才，学子遍布全国各地，11000名CMA持证者中，5600名来自优财！"
      },
      {
        "type":"txt",
        "src":"截至目前，优财（中国）CMA培训中心已成功举办26届中国管理会计实践论坛和学员颁证典礼，各种管理会计实战课、走进名企等活动100余期，参与人数达到2万人次，同时，线上直播课已经举办100余期，参与人数达到了10万人次，众多在职财务人员从中受益！"
      },
      {
        "type":"txt",
        "src":"未来，优财将会继续专注于CMA的培训事业，推进管理会计人才培养，为国家和企业输送出更多业财融合、推动企业发展的价值创造者！"
      }
    ]
  },
  {
    id: "canhui9",
    "src": require("@/assets/images/article/canhui9.jpg"),
    time:"2017-10-21",
    name: "en+数字化助力CMA，推动管理会计在国内的发展",
    content:[
      {
        "type":"txt",
        "src":"2017年，财务界最热词汇当属“人工智能”，随着“四大”财务机器人的相继问世，财务机器人是否能够取代财务人成了财务工作者最为关心的话题，业内权威人士随即表态，财务人不仅不会被“机器人”所取代， 而且还能让人工智能为我所用，但这一切都有一个大前提，那便是“转型成为管理会计”。自2016年底财政部在《会计改革与发展“十三五”规划纲要》中将管理会计列为“行业急需紧缺人才”以来，2017年，管理 会计人才建设步入了会计转型发展的快车道，就在几天前，财政部官网发布《财政部关于印发〈管理会计应用指引第100号——战略管理〉等22项管理会计应用指引的通知》，总结提炼了目前在企业普遍应用且较为成 熟的部分管理会计工具，再掀管理会计体系建设工作高潮。        "
      },
      {
        "type":"txt",
        "src":"2017年10月21日，由优财（中国）CMA培训中心和美国管理会计师协会（IMA）联合举办的“中国管理会计案例研讨会暨华南区CMA颁证典礼”在深圳 · 景明达酒店隆重举行。据统计，仅上半年CMA培训专家-优财管理 会计CMA招生人数便实现了16年同期的翻倍增长，进入下半年，优财相继于8月、9月、10月，分别在北京、天津、深圳举办管理会计研讨系列活动，借财政部全面推进管理会计体系建设东风，逐步推进CMA这一新一代 财务管理者的国际财务认证成为财务人身提升、职业转型的必备利器。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui9/img1.jpg")
      },
      {
        "type":"txt",
        "src":"此次研讨会邀请到了包括IMA美国管理会计师协会华南区商务拓展总监贺媛媛女士，优财（中国）CMA培训中心商务拓展总监刘泊成先生，毕马威中国管理咨询服务合伙人高人伯先生，en+数字化创始人兼CEO，前百 度移动游戏CFO曾亮，历任广州立白企业集团财务总监&审计总监，中国十大CFO陈明先生，深圳前海亚讯电子商务有限公司首席财务官、CMA会员李斌先生，黑弧奥美财务总监，前华为国家财务总监、CMA会员简进波在 内的众多国内一流机构和企业的重量级嘉宾参加，400余名企业高级管理人员和财务负责人现场聆听了此次思想盛宴。"
      },
      {
        "type":"txt",
        "src":"贺媛媛 女士致开幕词"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui9/img2.jpg")
      },
      {
        "type":"txt",
        "src":"IMA美国管理会计师协会华南区商务拓展总监"
      },
      {
        "type":"txt",
        "src":"贺媛媛女士向大家介绍了IMA（美国管理会计师协会）的发展历史，管理会计的定位，管理会计覆盖企业经营生产全过程的广泛应用，管理会计知识体系以及管理会计工具包含内容。并向大家讲解了CMA（美国注册管理会计师）的创立背景，能力素质框架和知名企业对于CMA的重视程度和组织CMA内训情况。"
      },
      {
        "type":"txt",
        "src":"贺媛媛女士提到，优财是IMA在中国首批授权机构，在我国是首屈一指的CMA培训机构，尤其是在2016年9月优财开始在深圳设立分校，开展线下CMA培训，这对广东地区考生来说，可谓一大福利。"
      },
      {
        "type":"txt",
        "src":"IMA成立于1919年可谓名副其实的“百年”老店，目前在世界范围内已有125个国家、265个分会，吸纳了90,000多名会员、其中55,000 CMA持证者。管理会计被定位为价值管理的常识和文化，是一个深度参与管理决策、制定规划与绩效管理系统、提供财务报告与控制方面专业能力的专业领域, 帮助管理者制定并实施组织战略。"
      },
      {
        "type":"txt",
        "src":"CMA是IMA创立的针对管理会计领域的全球专业资格认证，其知识体系和管理会计工具的应用全面系统的贯穿企业经营生产全过程，能够有效指导企业战略规划实践。近年来，IMA携手优财在广东地区组织多场形式多样的CMA管理会计知识普及和推广活动，极大的推动了广东地区CMA管理会计的飞速发展。"
      },
      {
        "type":"txt",
        "src":"刘泊成 先生致开幕词"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui9/img3.jpg")
      },
      {
        "type":"txt",
        "src":"优财（中国）CMA培训中心商务拓展总监"
      },
      {
        "type":"txt",
        "src":"刘泊成先生提到，粤港澳大湾区的快速建设，高端人才的引进是关键，而对于财务人来说，企业高级财务管理人员的关键职责要求包括的内部控制、风险管理、决策支持、投融资、成本控制均属于管理会计的知识体系，加之国家政策对于管理会计的大力推进，不难看出转型管理会计，成为精财务、懂业务、会管理、能决策的新一代财务管理者，是当代财务人实现个人职业生涯长远发展和及时抓住有利时机的必经之路。"
      },
      {
        "type":"txt",
        "src":"CMA作为新一代财务管理者的国际财务认证，集系统性与实践性于一身，被称为财务界MiniMBA。CMA培训专家-优财作为IMA中国首家授权机构，专注CMA培训行业9年，得到了众多财务人士和500强企业客户的青睐，尤其是在今年5月，优财全新发布“5.0产品”，业内独家应用“案例式教学”模式，并推出业内唯一公开出版的CMA教材辅导用书《美国注册管理会计师（CMA）应试策略及全真模拟测试》，教学模式和整个CMA教学辅导体系得到了广大CMA考生的高度认可，为推进我国管理会计体系的全面建设输送了大量的高级管理会计人才。"
      },
      {
        "type":"txt",
        "src":"高人伯 先生"
      },
      {
        "type":"txt",
        "src":"《中国共享服务的现状与财务人面临的挑战》"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui9/img4.jpg")
      },
      {
        "type":"txt",
        "src":"毕马威中国管理咨询服务合伙人"
      },
      {
        "type":"txt",
        "src":"高人伯先生充分分析了共享服务和“人工智能”的发展现状，明确财务人目前所面临的挑战，并就此提出了相关的观点和建议"
      },
      {
        "type":"txt",
        "src":"无论是财政部相关政策和“一带一路”的有利推进，都在加速企业对于兴建财务共享中心的需求，目前，超过七成的大型企业已经设立了共享服务中心，对于业务流程标准、频繁的基础性财务工作已经实现了高度的 集中管控，这就实现了财务管理的标准统一、数据集中、效率提升，与此同时，财务共享中心的设立也面临着人才管理挑战，因此共享中心也在向业务化、智能化转变。"
      },
      {
        "type":"txt",
        "src":"在谈及以RPA为趋势的财务智能化时，高人伯先生首先明确了RPA的定义及功能，并从科技进步对于财务行业影响的历史发展进程，提醒大家财务机器人走入财务领域已经近在眼前，未来的10-20年47%的就业机会都会 被机械自动化所取代，财务人考取CMA管理会计认证，实现财务职业转型，是十分必要的。"
      },
      {
        "type":"txt",
        "src":"曾亮  先生"
      },
      {
        "type":"txt",
        "src":"《数字化实践之业财融合》"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui9/img5.jpg")
      },
      {
        "type":"txt",
        "src":"en+数字化创始人兼CEO，前百度移动游戏CFO"
      },
      {
        "type":"txt",
        "src":"曾亮先生全面分析了当今企业财务管理所面临的趋势，人工智能逐步深入各行各业的情况下，在财务领域，传统的记账财务会被数字化所取代。他提到，在百度移动游戏三年CFO从业经历，一直在推进业财一体化， 企业从0开始，经过三年发展，平台流水实现30亿，按照传统配置财务团队应为20-30人，但实现业财一体化后只需7人；并提到，丰田的全球结算中心每年生产300万辆汽车，通过业财一体化只有两人。"
      },
      {
        "type":"txt",
        "src":"从企业角度讲，投资者国际化，专业化，智能化；监管机构智能化自动化；《中华人民共和国会计法》的修订把管理会计和内控合规纳入会计法的范畴；价值投资将成为未来资本市场的主旋律。无论是财务软件需求 变化、 数据的价值增大、新的内控框架指引颁布都在表明着一个事实——财务转型已势不可挡。而我国财务工作的主要矛盾已经转化为企业精细化管理的数字化需要和业财融合不充分不完善之间的矛盾；财务工作 最大的痛苦没有之一，就是把核算会计系统当成管理会计系统来用。"
      },
      {
        "type":"txt",
        "src":"为更好的适应瞬息万变的经营环境，企业急需财务管理转型，从管理系统迭代到管理会计人才培养多角度推进管理会计实践。曾亮先生还详细介绍了en+数字化作为国内第一家基于管理会计思维打造的数字化系统 ，不同于传统的核算会计系统，为企业提供多维度和全局视角的具体解决模式和方法。"
      },
      {
        "type":"txt",
        "src":"本次活动下午分享嘉宾分别是陈明先生《基于营销的管理会计实践—跳出财务搞管理，争做企业大管家》，李斌先生《管理会计实践之预算管理》，简进波先生《以客户为中心经营管理在华为的实践》。"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui9/img6.jpg")
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui9/img7.jpg")
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui9/img8.jpg")
      },
      {
        "type":"txt",
        "src":"本次活动下午分享嘉宾分别是陈明先生《基于营销的管理会计实践—跳出财务搞管理，争做企业大管家》，李斌先生《管理会计实践之预算管理》，简进波先生《以客户为中心经营管理在华为的实践》。 一系列事实表明，管理会计非常契合我国目前“新常态”的发展阶段，运用管理会计工具对财务数据进行整理分析、去伪存真，实现价值创造十分迫切和必要。管理会计在中国的发展充满了无穷的潜力，得到了政府 、学术界，商业界，教育界、媒体等的积极响应，投入了巨大精力探寻摸索，并取得了十分丰硕的成果，CMA作为新一代财务管理者的国际财务认证未来在中国必将大放异彩，有力支撑起我国世界顶级企业的运营和世 界经济强国的地位。"
      },
      {
        "type":"txt",
        "src":"截至目前，优财已举办线上线下管理会计公益讲座、论坛、沙龙几百场，参与人次逾10万人，众多在职财务人员从中受益。在今后的日子里，优财将持续致力于推进管理会计人才培养工作，让全国各地更多的财务工 作者能够通过CMA管理会计知识的学习，尽早完成职业转型，在财务道路上创造人生的辉煌。"
      },
      {
        "type":"txt",
        "src":"研讨会现场"
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui9/img9.jpg")
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui9/img10.jpg")
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui9/img11.jpg")
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui9/img12.jpg")
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui9/img13.jpg")
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui9/img14.jpg")
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui9/img15.jpg")
      }]
  },
  {
    id: "canhui10",
    "src": require("@/assets/images/article/canhui10.jpg"),
    time:"2017-08-31",
    name: "en+数字化独家赞助《中国管理会计案例研讨会》",
    content:[
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui10/img1.jpg")
      },
      {
        "type":"txt",
        "src":"2017年被称为智能化管理会计落地 “元年”, 智能化技术在管理会计领域的应用与发展速度之快，远远超出人们的预期, 以“移动支付、大数据、云计算、物联网、人工智能”为主要内容的信息技术，日益广泛深入地影响了社会经济和大家生活的方方面面，作为全面掌握企业数据信息的财务人，赶上了创新发展的机遇，同时也遇到了严峻的转型挑战。国家和企业的都指出了转型方向，现代会计需要转型为价值创造者--管理会计。en+数字化非常看好中国的管理会计和数字化的发展趋势，独家赞助了由优财CMA举办的《中国管理会计案例研讨会》，共绘中国管理会计的发展宏图。        "
      },
      {
        "type":"txt",
        "src":"2017年8月27日，由CMA培训专家-优财与美国管理会计师协会(IMA)共同主办，en+数字化独家赞助的2017年中国管理会计案例研讨会在北京国际艺苑皇冠假日酒店召开。这是CMA培训专家-优财携手IMA为落实财政部《关于全面推进管理会计体系建设的指导意见》(财会〔2014〕27号)、《管理会计基本指引》(财会[2016]10号)、《会计改革与发展“十三五”规划纲要》(财会[2016]19号)精神，发现、总结、提炼我国管理会计实践典型案例，加强管理会计理论与实践互动，加快管理会计在全国范围的推广应用，培养新一代财务管理者而举办的一场管理会计案例研讨盛会。        "
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui10/img1.jpg")
      },
      {
        "type":"img",
        "src":require("@/assets/images/article-img/canhui10/img3.jpg")
      },
      {
        "type":"txt",
        "src":"一系列事实表明，管理会计非常契合我国目前“新常态”的发展阶段，运用管理会计工具对财务数据进行整理分析、去伪存真，实现价值创造十分迫切和必要。管理会计在中国的发展充满了无穷的潜力，得到了政府、学术界，商业界，教育界、媒体等的积极响应，投入了巨大精力探寻摸索，并取得了十分丰硕的成果，en+数字化将继续为中国管理会计的发展做出贡献，继续引领中国数字化的潮流。"
      }
    ]
  }
]
const shipinList=[
  {
    id: "shipin6",
    src: require("@/assets/images/article/zhuanye18.jpg"),
    time:"2022-5-10",
    name: "言道DLP方案介绍2.0",
    content:[
      {
      "type":"video",
      "src":"https://cdn.enfry.com/yandao2.0.mp4"
      }
    ]
  },
  {
    id: "shipin1",
    src: require("@/assets/images/article/shipin1.jpg"),
    time:"2019-10-10",
    name: "用业财融合的方式帮助企业数字化转型",
    content:[
      {
      "type":"video",
      "src":"https://v.qq.com/txp/iframe/player.html?vid=j3006l5xp51"
      }
    ]
  },
  {
    id: "shipin2",
    src: require("@/assets/images/article/shipin2.jpg"),
    time:"2019-10-10",
    name: "管理会计发展的几个问题",
    content:[
      {
      "type":"video",
      "src":"https://v.qq.com/txp/iframe/player.html?vid=s3006oob9cb"
      }
    ]
  },
  {
    id: "shipin3",
    src: require("@/assets/images/article/shipin3.jpg"),
    time:"",
    name: "en+数字化助推企业数字化转型之CFO篇",
    content:[
      {
      "type":"video",
      "src":"https://cdn.enfry.com/cfo.mp4"
      }
    ]
  },
  {
    id: "shipin4",
    src: require("@/assets/images/article/shipin4.jpg"),
    time:"",
    name: "en+数字化助推企业数字化转型之CEO篇",
    content:[
      {
      "type":"video",
      "src":"https://cdn.enfry.com/ceo.mp4"
      }
    ]
  },
  {
    id: "shipin5",
    src: require("@/assets/images/article/zhuanye10.jpg"),
    time:"",
    name: "en+数字化，驭数有道",
    content:[
      {
      "type":"video",
      "src":"https://cdn.enfry.com/en%2B%E6%95%B0%E5%AD%97%E5%8C%96%EF%BC%8C%E9%A9%AD%E6%95%B0%E6%9C%89%E9%81%93.mp4"
      }
    ]
  }
]
export {
  zhuanyeList,
  canhuiList,
  shipinList
}